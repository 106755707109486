import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isCalling: [],
  callFailed: [],
  callSuccessful: [],
  administrators: [],
  administrator: '',
  error: ''
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CREATE_ADMINISTRATOR:
    return {
      ...state,
      isCalling: ['CREATE_ADMINISTRATOR'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.CREATE_ADMINISTRATOR_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['CREATE_ADMINISTRATOR'],
      administrator: action.payload
    }
  case actionTypes.CREATE_ADMINISTRATOR_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['CREATE_ADMINISTRATOR'],
      error: action.payload
    }
  case actionTypes.GET_ADMINISTRATORS:
    return {
      ...state,
      isCalling: ['GET_ADMINISTRATORS'],
      callFailed: [],
      callSuccessful: [],
      administrator: '',
      error: ''
    }
  case actionTypes.GET_ADMINISTRATORS_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['GET_ADMINISTRATORS'],
      administrators: action.payload.data
    }
  case actionTypes.GET_ADMINISTRATORS_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['GET_ADMINISTRATORS'],
      error: action.payload
    }
  case actionTypes.UPDATE_ADMINISTRATOR:
    return {
      ...state,
      isCalling: ['UPDATE_ADMINISTRATOR'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.UPDATE_ADMINISTRATOR_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['UPDATE_ADMINISTRATOR'],
      administrator: action.payload.data
    }
  case actionTypes.UPDATE_ADMINISTRATOR_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['UPDATE_ADMINISTRATOR'],
      error: action.payload
    }
  case actionTypes.UPDATE_ADMIN_PASSWORD:
    return {
      ...state,
      isCalling: ['UPDATE_ADMIN_PASSWORD'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.UPDATE_ADMIN_PASSWORD_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['UPDATE_ADMIN_PASSWORD'],
      administrator: action.payload.data
    }
  case actionTypes.UPDATE_ADMIN_PASSWORD_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['UPDATE_ADMIN_PASSWORD'],
      error: action.payload
    }
  case actionTypes.DELETE_ADMINISTRATOR:
    return {
      ...state,
      isCalling: ['DELETE_ADMINISTRATOR'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.DELETE_ADMINISTRATOR_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['DELETE_ADMINISTRATOR'],
    }
  case actionTypes.DELETE_ADMINISTRATOR_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['DELETE_ADMINISTRATOR'],
      error: action.payload
    }
  default: 
    return state
  }
}