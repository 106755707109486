import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Row, Col, Button, Image, Modal, Form } from 'react-bootstrap';
import { Field, reduxForm } from "redux-form";
import { addAdministrator, modifyAdministrator, fetchAdministrators, removeAdministrator, updateAdminPassword } from 'redux/actions/administrator';
import Loader from "components/ui/Loader";
import ConfirmationModal from 'components/ui/ConfirmationModal';
import { isAdministratorLoading } from 'redux/selectors';
import DeleteImage from 'images/DeleteWhite.png';
import EditImage from 'images/EditWhite.png';
import checkImage from 'images/check.png';
import uncheckImage from 'images/uncheck.png';
import { reduxFormInput } from 'components/form/ReduxForm';
import { required, fccEmail, mobileNumber, passwordsMatch } from 'util/validationUtil';
import EditAdministator from 'containers/modal/EditAdministrator';

function ManageAdministrators(props){
  const [modalShow, setModalShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [administratorId, setAdministratorId] = useState(0);

  const { handleSubmit, isLoading, administrators, administrator, isDeleted } = props;

  const initialValues = administrators.find(admin => admin.id === administratorId);
  if(initialValues) initialValues.is_active = (initialValues.is_active === '1' || initialValues.is_active === 1) ? 1 : 0;

  useEffect(() => {
    props.fetchAdministrators();
  }, [administrator, isDeleted])

  const editProfile = useCallback(id => {
    setAdministratorId(id);
    setEditShow(true);
  }, [initialValues]);

  const canDelete = useCallback( id => {
    setModalShow(true);
    setAdministratorId(id);
  }, []);

  const confirmDeletion = useCallback( id => {
    setModalShow(false);
    props.removeAdministrator(id);
  }, []);

  const createAdmin = useCallback( () => {
    setProfileShow(true);
  }, []);

  const submitFn = useCallback( data => {
    props.addAdministrator(data);
    setProfileShow(false);
  }, []);

  const clearFields = useCallback( () => {
    setProfileShow(false);
    props.dispatch(reset('createAdmin'));
  }, []);

  const editSubmit = useCallback( data => {
    props.modifyAdministrator(data.id, data);
    setEditShow(false);
  }, []);

  const updatePassword = useCallback( id => {
    setAdministratorId(id);
    setPasswordShow(true);
  }, []);

  const submitPasswordFn = useCallback( data => {
    props.updateAdminPassword(administratorId, data);
    setPasswordShow(false);
  }, [administratorId]);
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: '',
            accessor: 'id',
            Cell: ({ cell: { value } }) => <EditCell id={value} delete={canDelete} edit={editProfile} update={updatePassword} />
          },
          {
            Header: 'Last Name',
            accessor: 'last_name'
          },
          {
            Header: 'First Name',
            accessor: 'first_name'
          },
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'Phone',
            accessor: 'phone_number',
            Cell: ({ cell: { value }  }) => {
              if(value)
                return value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
              return ''
            }
          },
          {
            Header: 'Active',
            accessor: 'is_active',
            Cell: ({ cell: { value } }) => <CheckStatus status={value}/>
          }
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Manage Administrators</h3>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={administrators}
              tableHeader={false} />
          </div>
          <Spacer size={3} />
          <Button className="btn-second-alt" variant="secondary-alt"onClick={createAdmin} >
            Add New Administrator
          </Button>
          <AddEditAdmin 
            show={profileShow}
            onHide={clearFields}
            onSubmit={handleSubmit(submitFn)}
          />
          <EditAdministator 
            show={editShow}
            onHide={() => setEditShow(false)}
            update={editSubmit}
            initialValues={initialValues}
          />
          <UpdatePassword 
            show={passwordShow}
            onHide={() => setPasswordShow(false)}
            onSubmit={handleSubmit(submitPasswordFn)}
            data={initialValues}
          />
          <ConfirmationModal
            show={modalShow}
            id={administratorId}
            onHide={() => setModalShow(false)}
            onConfirm={confirmDeletion}
          />
        </Container>
      </div>
    </>
  )
}

const EditCell = (props) => {
  return(
    <>
      <Image className="editdelete" src={EditImage} onClick={() => props.edit(props.id)} />
      <Image className="editdelete" src={DeleteImage} onClick={() => props.delete(props.id)} />
      <Button className="link" variant="link" size="sm" onClick={() => props.update(props.id)}>Reset Password</Button>
    </>
  )
}

const CheckStatus = (props) => {
  return(
    <>
      { props.status === 1 || props.status === '1' ?
        <Image className="checkuncheck" src={checkImage}/> : 
        <Image className="checkuncheck" src={uncheckImage}/> }
    </>
  )
}

const AddEditAdmin = (props) => {
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Add New Administrator</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container fluid>
            <Spacer size={3} />
            <Form id="createAdminUI" className="w-100" >
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        First Name:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="first_name" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Last Name:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="last_name" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Email Address:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, fccEmail]}
                        name="email" 
                        type="email" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Phone Number:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, mobileNumber]}
                        name="phone_number" 
                        type="text" 
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Confirm Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, passwordsMatch]}
                        name="confirm_password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={2} />
                  <Button type="submit" className="primary" variant="primary-alt" size="sm">
                    Save
                  </Button>
                  <Spacer size={2} />
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const UpdatePassword = (props) => {
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Reset Password</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container fluid>
            <Spacer size={3} />
            <h5>{props.data && props.data.last_name}, {props.data && props.data.first_name}</h5>
            <Spacer size={3} />
            <Form id="updatePasswordUI" className="w-100" >
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        New Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Confirm Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, passwordsMatch]}
                        name="confirm_password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={2} />
                  <Button type="submit" className="primary" variant="primary-alt" size="sm">
                    Save
                  </Button>
                  <Spacer size={2} />
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => {
  const isLoading = isAdministratorLoading(state);
  const administrators = state.administratorReducer.administrators;
  const administrator = state.administratorReducer.administrator;
  const isDeleted = state.administratorReducer.callSuccessful.includes('DELETE_ADMINISTRATOR');
  return { isLoading, administrators, administrator, isDeleted }
}

const connectedReduxForm = reduxForm({
  form: 'createAdmin',
  enableReinitialize: true,
})(ManageAdministrators);

export default connect(
  mapStateToProps,
  {
    fetchAdministrators,
    removeAdministrator,
    addAdministrator,
    modifyAdministrator,
    updateAdminPassword
  }
)(connectedReduxForm)