import React from "react";
import { Switch } from "react-router-dom";

import Header from 'components/layout/Header';
import Navbar from 'components/sidebar/Navbar';
import Sidebar from 'components/sidebar/AdminSidebar';
import IdleTimer from 'react-idle-timer';
import routes from 'routes';
import PrivateRoute from 'routes/privateRoute';
import { destroyLocal } from 'util/storageUtil';

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.state = {
      timeout: 1000 * 60 * 30,
      backgroundColor: "black",
      activeColor: "info"
    };
    this.mainPanel = React.createRef();
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />
        <Header type="Administrator Portal" subtitle="Mediation Scheduling Application" />
        <div className="wrapper" id="dashboard">
          <Sidebar
            {...this.props}
            routes={routes.adminRoutes}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
          <div className="main-panel" ref={this.mainPanel}>
            <Navbar {...this.props} />
            <Switch>
              {routes.adminRoutes.map((prop, key) => {
                return (
                  <PrivateRoute
                    user={prop.layout.slice(1)}
                    path={prop.layout+prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </div>
        </div>
      </>
    );
  }
  _onAction(e) {}
 
  _onActive(e) {}
 
  _onIdle(e) {
    destroyLocal();
    this.props.history.push('/')
  }
}

export default AdminDashboard;
