import { actionTypes } from './actionTypes';
import axios from 'axios';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

export function createAdministrator() {
  return {
    type: actionTypes.CREATE_ADMINISTRATOR
  }
}
  
export function createAdministratorSuccess(administrator) {
  return {
    type: actionTypes.CREATE_ADMINISTRATOR_SUCCESS,
    payload: administrator
  }
}
  
export function createAdministratorFailure(error) {
  return {
    type: actionTypes.CREATE_ADMINISTRATOR_FAILURE,
    payload: error
  }
}

export function getAdministrators() {
  return {
    type: actionTypes.GET_ADMINISTRATORS
  }
}
    
export function getAdministratorsSuccess(administrators) {
  return {
    type: actionTypes.GET_ADMINISTRATORS_SUCCESS,
    payload: administrators
  }
}
    
export function getAdministratorsFailure(error) {
  return {
    type: actionTypes.GET_ADMINISTRATORS_FAILURE,
    payload: error
  }
}

export function updateAdministrator() {
  return {
    type: actionTypes.UPDATE_ADMINISTRATOR
  }
}
  
export function updateAdministratorSuccess(administrator) {
  return {
    type: actionTypes.UPDATE_ADMINISTRATOR_SUCCESS,
    payload: administrator
  }
}
  
export function updateAdministratorFailure(error) {
  return {
    type: actionTypes.UPDATE_ADMINISTRATOR_FAILURE,
    payload: error
  }
}

export function updatePassword() {
  return {
    type: actionTypes.UPDATE_ADMIN_PASSWORD
  }
}
  
export function updatePasswordSuccess(administrator) {
  return {
    type: actionTypes.UPDATE_ADMIN_PASSWORD_SUCCESS,
    payload: administrator
  }
}
  
export function updatePasswordFailure(error) {
  return {
    type: actionTypes.UPDATE_ADMIN_PASSWORD_FAILURE,
    payload: error
  }
}

export function deleteAdministrator() {
  return {
    type: actionTypes.DELETE_ADMINISTRATOR
  }
}
  
export function deleteAdministratorSuccess() {
  return {
    type: actionTypes.DELETE_ADMINISTRATOR_SUCCESS
  }
}
  
export function deleteAdministratorFailure(error) {
  return {
    type: actionTypes.DELETE_ADMINISTRATOR_FAILURE,
    payload: error
  }
}

export const addAdministrator = (admin) => {
  return dispatch => {
    dispatch(createAdministrator());
    axios
      .post(`${constants.url}/administrators`, admin,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(createAdministratorSuccess(res)))
      .catch(err => dispatch(createAdministratorFailure(err)))
  }
}

export const fetchAdministrators = () => {
  return dispatch => {
    dispatch(getAdministrators());
    axios
      .get(`${constants.url}/administrators`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getAdministratorsSuccess(res.data)))
      .catch(err => dispatch(getAdministratorsFailure(err)))
  }
}

export const modifyAdministrator = (id, admin) => {
  return dispatch => {
    dispatch(updateAdministrator());
    axios
      .put(`${constants.url}/administrators/${id}`, admin,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateAdministratorSuccess(res.data)))
      .catch(err => dispatch(updateAdministratorFailure(err)))
  }
}

export const updateAdminPassword = (id, password) => {
  return dispatch => {
    dispatch(updatePassword());
    axios
      .put(`${constants.url}/administrators/password/${id}`, password,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updatePasswordSuccess(res.data)))
      .catch(err => dispatch(updatePasswordFailure(err)))
  }
}

export const resetAdminForgotPassword = (id, password, token) => {
  return dispatch => {
    dispatch(updatePassword());
    axios
      .put(`${constants.url}/administrators/password/${id}`, password,
        { headers: { Authorization: `Bearer ${token}` } })
      .then(res => dispatch(updatePasswordSuccess(res.data)))
      .catch(err => dispatch(updatePasswordFailure(err)))
  }
}

export const removeAdministrator = (id) => {
  return dispatch => {
    dispatch(deleteAdministrator());
    axios
      .delete(`${constants.url}/administrators/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(deleteAdministratorSuccess()))
      .catch(err => dispatch(deleteAdministratorFailure(err)))
  }
}
