import { actionTypes } from './actionTypes';
import axios from "axios";
import constants from "config/constants";

export function userLoginRequest() {
  return {
    type: actionTypes.USER_LOGIN_REQUEST
  }
}

export function userLoginSuccess(user) {
  return {
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: user
  }
}

export function userLoginFailure(error) {
  return {
    type: actionTypes.USER_LOGIN_FAILURE,
    payload: error
  }
}

export function forgotPasswordReset() {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET
  }
}

export function forgotPasswordResetSuccess(data) {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET_SUCCESS,
    payload: data
  }
}

export function forgotPasswordResetFailure(error) {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET_FAILIRE,
    payload: error
  }
}

export function passwordTokenCheck() {
  return {
    type: actionTypes.CHECK_PASSWORD_TOKEN
  }
}

export function passwordTokenCheckSuccess(data) {
  return {
    type: actionTypes.CHECK_PASSWORD_TOKEN_SUCCESS,
    payload: data
  }
}

export function passwordTokenCheckFailure(error) {
  return {
    type: actionTypes.CHECK_PASSWORD_TOKEN_FAILURE,
    payload: error
  }
}

export const authenticateUser = (user, email, password) => {
  return dispatch => {
    dispatch(userLoginRequest());
    axios
      .post(`${constants.url}/login/${user}`, {
        email: email,
        password: password
      })
      .then(res => dispatch(userLoginSuccess(res.data)))
      .catch(err => dispatch(userLoginFailure(err)));
  };
};

export const userPasswordReset = (user, email) => {
  return dispatch => {
    dispatch(forgotPasswordReset());
    axios
      .post(`${constants.url}/login/${user}/forgot`, {
        email: email
      })
      .then(res => dispatch(forgotPasswordResetSuccess(res.data)))
      .catch(err => dispatch(forgotPasswordResetFailure(err)));
  };
};

export const checkPasswordToken = (user, token) => {
  return dispatch => {
    dispatch(passwordTokenCheck());
    axios
      .get(`${constants.url}/login/${user}/password/token?token=${token}`)
      .then(res => dispatch(passwordTokenCheckSuccess(res.data)))
      .catch(err => dispatch(passwordTokenCheckFailure(err)));
  };
};
