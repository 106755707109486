import { adminRoutes } from './adminRoutes';
import { mediatorRoutes } from './mediatorRoutes';
import { schedulerRoutes } from './schedulerRoutes';
import LandingPage from 'containers/LandingPage';

const commonRoutes = [
  {
    path: "/",
    component: LandingPage,
    isExact: true,
    name: "Landing"
  }
];

export default {
  adminRoutes,
  mediatorRoutes,
  schedulerRoutes,
  commonRoutes
};