import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Spacer from 'components/layout/Spacer';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.sidebar = React.createRef();
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
      >
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <main className="fc-content">
            <Spacer size={30} />
            <Container fluid>
              <Row>
                <Col>
                  <aside>
                    <ul>
                      <li className="back"><Link to="/scheduler/panel"><h6>Back to Portal</h6></Link></li>
                      <li><Spacer size={5}/></li>
                      <li><Link to="/scheduler/manage/mediations"><h5>Manage Mediations</h5></Link></li>
                      <li><Link to="/scheduler/schedule/mediations"><h5>Schedule Mediations</h5></Link></li>
                      <li><Link to="/scheduler/setpassword"><h5>Change Password</h5></Link></li>
                    </ul>
                    <Spacer size={30} />
                  </aside>
                </Col>
              </Row>
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default Sidebar;
