import React from 'react';
import Spacer from 'components/layout/Spacer';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { allowMediatorPasswordChange } from 'redux/actions/mediator';
import { connect } from 'react-redux';

function MediatorPanel(props) {

  props.allowMediatorPasswordChange();

  return(
    <>
      <div className="fc-content">
        <Spacer size={30} />
        <Container fluid>
          <Row>
            <Col xs="12" md="10">
              <Row className="text-center" >
                <Col className="dashboard-items" >
                  <Link to="/mediator/profile">
                    <Button variant="secondary">
                        View Profile
                    </Button>
                  </Link>
                  <Spacer size={10} />
                  <Link to="/mediator/availability">
                    <Button variant="secondary">
                        Plan Availability
                    </Button>
                  </Link>
                  <Spacer size={10} />
                  <Link to="/mediator/mediations">
                    <Button variant="secondary">
                        View Mediations
                    </Button>
                  </Link>
                  <Spacer size={10} />
                  <Link to="/mediator/setpassword">
                    <Button variant="secondary">
                        Change Password
                    </Button>
                  </Link>
                  <Spacer size={30} />
                </Col>
              </Row>
            </Col>
            <Col md="2"></Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default connect(
  null, 
  {
    allowMediatorPasswordChange
  })(MediatorPanel);
