import React from "react";
import { Form } from "react-bootstrap";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';

const reduxFormInput = (props) => {
  const {
    input, 
    type,
    placeholder,
    meta: { 
      touched, 
      error 
    }
  } = props;

  return (
    <>
      <Form.Control
        className={touched && error && "input-error"}
        {...input}
        type={type}
        placeholder={placeholder}
      />
      {touched && error && <span className="error">{error}</span>}
    </>
  )
}

const isWeekend = (date) => {
  return ([0,6].indexOf(new Date(date).getDay()) != -1)
}

const reduxDateInput = (props) => {
  const {input, placeholder, defaultValue, meta: {touched, error} } = props;
  return (
    <>
      <DatePicker 
        {...input}
        dateFormat="MM/dd/yyyy"
        selected={input.value ? new Date(input.value) : null} 
      />
      {touched && error && <span className="error">{error}</span>}
    </>
  )
};

const reduxWeekDateInput = (props) => {
  const {input, placeholder, defaultValue, meta: {touched, error} } = props;
  return (
    <>
      <DatePicker 
        {...input}
        dateFormat="MM/dd/yyyy"
        minDate={new Date()}
        selected={input.value ? isWeekend(input.value) ? null : new Date(input.value) : null} 
      />
      {touched && error && <span className="error">{error}</span>}
    </>
  )
};

const reduxTimeInput = (props) => {
  const {
    input, 
    type,
    step,
    placeholder,
    meta: { 
      touched, 
      error 
    }
  } = props;

  return (
    <>
      <Form.Control
        className={touched && error && "input-error"}
        {...input}
        type={type}
        step={step}
        placeholder={placeholder}
      />
      {touched && error && <span className="error">{error}</span>}
    </>
  )
}

const reactTimePicker = (props) => {
  const {
    input,
    meta: { 
      touched, 
      error 
    }
  } = props;
  return (
    <>
      <DatePicker 
        {...input}
        selected={input.value} 
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
      {touched && error && <span className="error">{error}</span>}
    </>
  )
}

const reduxFormTextarea = (props) => {
  const {
    input,
    placeholder,
    rows,
    meta: { 
      touched, 
      error 
    }
  } = props;

  return (
    <>
      <Form.Control
        className={touched && error && "input-error"}
        {...input}
        placeholder={placeholder}
        as="textarea"
        rows={rows}
      />
      {touched && error && <span className="error">{error}</span>}
    </>
  )
}

const reduxFormSelect = (props) => {
  const {
    input,
    options,
    meta: { 
      touched, 
      error 
    }
  } = props;

  const handleBlur = () => {
    setTimeout(() => {
      const { input } = props;
      input.onBlur(input.value);
    }, 1);
  };

  return(
    <>
      <Select
        {...input}
        //value={(input.value === '') ? null : options.find(obj => obj.value === input.value)}
        value={
          typeof input.value === "string"
            ? options.filter(option => option.value === input.value)
            : input.value
        }
        onChange={(value) => input.onChange(value)}
        onBlur={e => e.preventDefault()}
        options={options}
      />
      {touched && error && <span className="error">{error}</span>}
    </>
  )
}

export {
  reduxFormInput,
  reduxFormTextarea,
  reduxFormSelect,
  reduxDateInput,
  reduxWeekDateInput,
  reduxTimeInput,
  reactTimePicker
}