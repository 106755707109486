import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isCalling: [],
  callFailed: [],
  callSucessful: [],
  certifications: [],
  certification: '',
  error: ''
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CREATE_CERTIFICATION:
    return {
      ...state,
      isCalling: ['CREATE_CERTIFICATION'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.CREATE_CERTIFICATION_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['CREATE_CERTIFICATION'],
      certification: action.payload
    }
  case actionTypes.CREATE_CERTIFICATION_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['CREATE_CERTIFICATION'],
      error: action.payload
    }
  case actionTypes.GET_CERTIFICATIONS:
    return {
      ...state,
      isCalling: ['GET_CERTIFICATIONS'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.GET_CERTIFICATIONS_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['GET_CERTIFICATIONS'],
      certifications: action.payload.data
    }
  case actionTypes.GET_CERTIFICATIONS_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['GET_CERTIFICATIONS'],
      error: action.payload
    }
  case actionTypes.UPDATE_CERTIFICATION:
    return {
      ...state,
      isCalling: ['UPDATE_CERTIFICATION'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.UPDATE_CERTIFICATION_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['UPDATE_CERTIFICATION'],
      certification: action.payload.data
    }
  case actionTypes.UPDATE_CERTIFICATION_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['UPDATE_CERTIFICATION'],
      error: action.payload.data
    }
  case actionTypes.DELETE_CERTIFICATION:
    return {
      ...state,
      isCalling: ['DELETE_CERTIFICATION'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.DELETE_CERTIFICATION_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['DELETE_CERTIFICATION'],
    }
  case actionTypes.DELETE_CERTIFICATION_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['DELETE_CERTIFICATION'],
      error: action.payload
    }
  default: 
    return state
  }
}