import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Logo from 'images/FccLogoWhite.png'
import { withRouter, Link } from 'react-router-dom';
import { Image, Container, Row, Col, Button } from 'react-bootstrap';
import constants from 'config/constants';
import { getValueFromLocal, destroyLocal } from 'util/storageUtil';
import { clearExistingMediator } from 'redux/actions/mediator';

class Header extends React.Component {

  logoutHandler = e => {
    e.preventDefault();
    destroyLocal();
    // return <Redirect to='/' />
    this.props.clearExistingMediator();
    this.props.history.push('/');
  }

  render() {
    const role = getValueFromLocal(constants.ROLE);
    const name = getValueFromLocal(constants.NAME);
    const { subtitle, type } = this.props;
    return(
      <header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="1" md="1" lg="2" >
              <Link to={`/${role}/panel`} className="logo">
                <Image className="logo" src={Logo} />
              </Link>
            </Col>
            <Col className="title" xs="10" md="10" lg="8">
              { subtitle &&
                <h3>{subtitle}</h3>
              }
              <h2>{type}</h2>
            </Col>
            <Col xs md="auto d-none d-lg-block d-xl-block" lg="2" style={{textAlign: "center"}}>
              <p>{ name }</p>
              <p>{ moment.utc(new Date()).format('MMM. D, YYYY') }</p>
              <Button className="logout" variant="link" onClick={this.logoutHandler}>Log Out</Button>
            </Col>
          </Row>
        </Container>
      </header>
    )
  }
}

export default withRouter(connect(null, { clearExistingMediator })(Header));
