import Panel from 'containers/scheduler/Panel';
import ManageMediations from 'containers/admin/ManageMediations';
import ScheduleMediations from 'containers/admin/ScheduleMediations';
import CreateNewMediation from 'containers/admin/CreateNewMediation';
import EditMediation from 'containers/admin/EditMediation';
import SchedulerPasswordSet from 'containers/scheduler/SchedulerPasswordSet';

const schedulerRoutes = [
  {
    path: "/panel",
    component: Panel,
    name: "Scheduler",
    layout: "/scheduler"
  },
  {
    path: "/manage/mediations",
    component: ManageMediations,
    name: "Manage Mediations",
    layout: "/scheduler"
  },
  {
    path: "/mediation/edit",
    component: EditMediation,
    name: "Edit Mediation",
    layout: "/scheduler"
  },
  {
    path: "/mediation",
    component: CreateNewMediation,
    name: "Mediation",
    layout: "/scheduler"
  },
  {
    path: "/schedule/mediations",
    component: ScheduleMediations,
    name: "Schedule Mediations",
    layout: "/scheduler"
  },
  {
    path: "/setpassword",
    component: SchedulerPasswordSet,
    name: "Scheduler Password Set",
    layout: "/scheduler"
  }
];

export { schedulerRoutes };
