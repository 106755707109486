import { actionTypes } from './actionTypes';
import axios from 'axios';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

export function createMediation() {
  return {
    type: actionTypes.CREATE_MEDIATION
  }
}

export function createMediationSuccess(mediation) {
  return {
    type: actionTypes.CREATE_MEDIATION_SUCCESS,
    payload: mediation
  }
}

export function createMediationFailure(error) {
  return {
    type: actionTypes.CREATE_MEDIATION_FAILURE,
    payload: error
  }
}

export function getMediation() {
  return {
    type: actionTypes.GET_MEDIATION
  }
}
  
export function getMediationSuccess(mediation) {
  let existingMediation = mediation;
  existingMediation.mediation_date = new Date(mediation.mediation_date);
  return {
    type: actionTypes.GET_MEDIATION_SUCCESS,
    payload: existingMediation
  }
}
  
export function getMediationFailure(error) {
  return {
    type: actionTypes.GET_MEDIATION_FAILURE,
    payload: error
  }
}

export function getMediations() {
  return {
    type: actionTypes.GET_MEDIATIONS
  }
}
  
export function getMediationsSuccess(mediations) {
  return {
    type: actionTypes.GET_MEDIATIONS_SUCCESS,
    payload: mediations
  }
}
  
export function getMediationsFailure(error) {
  return {
    type: actionTypes.GET_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getNewMediations() {
  return {
    type: actionTypes.GET_NEW_MEDIATIONS
  }
}
  
export function getNewMediationsSuccess(newMediations) {
  return {
    type: actionTypes.GET_NEW_MEDIATIONS_SUCCESS,
    payload: newMediations
  }
}
  
export function getNewMediationsFailure(error) {
  return {
    type: actionTypes.GET_NEW_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getNotifiedMediations() {
  return {
    type: actionTypes.GET_NOTIFIED_MEDIATIONS
  }
}
  
export function getNotifiedMediationsSuccess(notifiedMediations) {
  return {
    type: actionTypes.GET_NOTIFIED_MEDIATIONS_SUCCESS,
    payload: notifiedMediations
  }
}
  
export function getNotifiedMediationsFailure(error) {
  return {
    type: actionTypes.GET_NOTIFIED_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getAssignedMediations() {
  return {
    type: actionTypes.GET_ASSIGNED_MEDIATIONS
  }
}
  
export function getAssignedMediationsSuccess(assignedMediations) {
  return {
    type: actionTypes.GET_ASSIGNED_MEDIATIONS_SUCCESS,
    payload: assignedMediations
  }
}
  
export function getAssignedMediationsFailure(error) {
  return {
    type: actionTypes.GET_ASSIGNED_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getDeclinedMediations() {
  return {
    type: actionTypes.GET_DECLINED_MEDIATIONS
  }
}
  
export function getDeclinedMediationsSuccess(declinedMediations) {
  return {
    type: actionTypes.GET_DECLINED_MEDIATIONS_SUCCESS,
    payload: declinedMediations
  }
}
  
export function getDeclinedMediationsFailure(error) {
  return {
    type: actionTypes.GET_DECLINED_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getMAssignedMediations() {
  return {
    type: actionTypes.GET_MEDIATOR_ASSIGNED_MEDIATIONS
  }
}
  
export function getMAssignedMediationsSuccess(assignedMediations) {
  return {
    type: actionTypes.GET_MEDIATOR_ASSIGNED_MEDIATIONS_SUCCESS,
    payload: assignedMediations
  }
}
  
export function getMAssignedMediationsFailure(error) {
  return {
    type: actionTypes.GET_MEDIATOR_ASSIGNED_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getMConfirmedDeclinedMediations() {
  return {
    type: actionTypes.GET_MCONFIRMED_DECLINED_MEDIATIONS
  }
}
  
export function getMConfirmedDeclinedMediationsSuccess(condecMediations) {
  return {
    type: actionTypes.GET_MCONFIRMED_DECLINED_MEDIATIONS_SUCCESS,
    payload: condecMediations
  }
}
  
export function getMConfirmedDeclinedMediationsFailure(error) {
  return {
    type: actionTypes.GET_MCONFIRMED_DECLINED_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getUpcomingMediations() {
  return {
    type: actionTypes.GET_UPCOMING_MEDIATIONS
  }
}
  
export function getUpcomingMediationsSuccess(upcomingMediations) {
  return {
    type: actionTypes.GET_UPCOMING_MEDIATIONS_SUCCESS,
    payload: upcomingMediations
  }
}
  
export function getUpcomingMediationsFailure(error) {
  return {
    type: actionTypes.GET_UPCOMING_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getRecentMediations() {
  return {
    type: actionTypes.GET_RECENT_MEDIATIONS
  }
}
  
export function getRecentMediationsSuccess(recentMediations) {
  return {
    type: actionTypes.GET_RECENT_MEDIATIONS_SUCCESS,
    payload: recentMediations
  }
}
  
export function getRecentMediationsFailure(error) {
  return {
    type: actionTypes.GET_RECENT_MEDIATIONS_FAILURE,
    payload: error
  }
}

export function getMediationsReport() {
  return {
    type: actionTypes.GET_MEDIATION_REPORT
  }
}
  
export function getMediationsReportSuccess(report) {
  return {
    type: actionTypes.GET_MEDIATION_REPORT_SUCCESS,
    payload: report
  }
}
  
export function getMediationsReportFailure(error) {
  return {
    type: actionTypes.GET_MEDIATION_REPORT_FAILURE,
    payload: error
  }
}

export function getConfirmationRate() {
  return {
    type: actionTypes.GET_CONFIRMATION_RATE
  }
}
  
export function getConfirmationRateSuccess(rate) {
  return {
    type: actionTypes.GET_CONFIRMATION_RATE_SUCCESS,
    payload: rate
  }
}
  
export function getConfirmationRateFailure(error) {
  return {
    type: actionTypes.GET_CONFIRMATION_RATE_FAILURE,
    payload: error
  }
}

export function updateMediation() {
  return {
    type: actionTypes.UPDATE_MEDIATION
  }
}

export function updateMediationSuccess(mediation) {
  return {
    type: actionTypes.UPDATE_MEDIATION_SUCCESS,
    payload: mediation
  }
}

export function updateMediationFailure(error) {
  return {
    type: actionTypes.UPDATE_MEDIATION_FAILURE,
    payload: error
  }
}

export function deleteMediation() {
  return {
    type: actionTypes.DELETE_MEDIATION
  }
}
  
export function deleteMediationSuccess() {
  return {
    type: actionTypes.DELETE_MEDIATION_SUCCESS
  }
}
  
export function deleteMediationFailure(error) {
  return {
    type: actionTypes.DELETE_MEDIATION_FAILURE,
    payload: error
  }
}

export const addMediation = (mediation) => {
  return dispatch => {
    dispatch(createMediation());
    axios
      .post(`${constants.url}/mediations`, mediation,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(createMediationSuccess(res)))
      .catch(err => dispatch(createMediationFailure(err)))
  }
}

export const fetchMediation = (id) => {
  return dispatch => {
    dispatch(getMediation());
    axios
      .get(`${constants.url}/mediations/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediationSuccess(res.data)))
      .catch(err => dispatch(getMediationFailure(err)))
  }
}

export const fetchAllMediations = () => {
  return dispatch => {
    dispatch(getMediations());
    axios
      .get(`${constants.url}/mediations`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediationsSuccess(res.data)))
      .catch(err => dispatch(getMediationsFailure(err)))
  }
}

export const fetchMediations = (id) => {
  return dispatch => {
    dispatch(getMediations());
    axios
      .get(`${constants.url}/mediations/paginate/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediationsSuccess(res.data)))
      .catch(err => dispatch(getMediationsFailure(err)))
  }
}

export const fetchNewMediations = () => {
  return dispatch => {
    dispatch(getNewMediations());
    axios
      .get(`${constants.url}/mediations/new`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getNewMediationsSuccess(res.data)))
      .catch(err => dispatch(getNewMediationsFailure(err)))
  }
}

export const fetchNotifiedMediations = () => {
  return dispatch => {
    dispatch(getNotifiedMediations());
    axios
      .get(`${constants.url}/mediations/notified`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getNotifiedMediationsSuccess(res.data)))
      .catch(err => dispatch(getNotifiedMediationsFailure(err)))
  }
}

export const fetchAssignedMediations = () => {
  return dispatch => {
    dispatch(getAssignedMediations());
    axios
      .get(`${constants.url}/mediations/assigned`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getAssignedMediationsSuccess(res.data)))
      .catch(err => dispatch(getAssignedMediationsFailure(err)))
  }
}

export const fetchDeclinedMediations = () => {
  return dispatch => {
    dispatch(getDeclinedMediations());
    axios
      .get(`${constants.url}/mediations/declined`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getDeclinedMediationsSuccess(res.data)))
      .catch(err => dispatch(getDeclinedMediationsFailure(err)))
  }
}

export const fetchMAssignedMediations = (id) => {
  return dispatch => {
    dispatch(getMAssignedMediations());
    axios
      .get(`${constants.url}/mediations/assigned/mediator/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMAssignedMediationsSuccess(res.data)))
      .catch(err => dispatch(getMAssignedMediationsFailure(err)))
  }
}

export const fetchMConfirmedDeclinedMediations = (id) => {
  return dispatch => {
    dispatch(getMConfirmedDeclinedMediations());
    axios
      .get(`${constants.url}/mediations/confirmeddeclined/mediator/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMConfirmedDeclinedMediationsSuccess(res.data)))
      .catch(err => dispatch(getMConfirmedDeclinedMediationsFailure(err)))
  }
}

export const fetchUpcomingMediations = (id) => {
  return dispatch => {
    dispatch(getUpcomingMediations());
    axios
      .get(`${constants.url}/mediations/upcoming/mediator/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getUpcomingMediationsSuccess(res.data)))
      .catch(err => dispatch(getUpcomingMediationsFailure(err)))
  }
}

export const fetchRecentMediations = (id) => {
  return dispatch => {
    dispatch(getRecentMediations());
    axios
      .get(`${constants.url}/mediations/recent/mediator/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getRecentMediationsSuccess(res.data)))
      .catch(err => dispatch(getRecentMediationsFailure(err)))
  }
}

export const fetchReport = (start_date, end_date) => {
  return dispatch => {
    dispatch(getMediationsReport());
    axios
      .get(`${constants.url}/mediations/report?start_date=${start_date}&end_date=${end_date}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediationsReportSuccess(res.data)))
      .catch(err => dispatch(getMediationsReportFailure(err)))
  }
}

export const fetchConfirmationRate = (id) => {
  return dispatch => {
    dispatch(getConfirmationRate());
    axios
      .get(`${constants.url}/mediations/confirmationrate/mediator/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getConfirmationRateSuccess(res.data)))
      .catch(err => dispatch(getConfirmationRateFailure(err)))
  }
}

export const modifyMediation = (id, mediation) => {
  return dispatch => {
    dispatch(updateMediation());
    axios
      .put(`${constants.url}/mediations/${id}`, mediation,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateMediationSuccess(res)))
      .catch(err => dispatch(updateMediationFailure(err)))
  }
}

export const removeMediation = (id) => {
  return dispatch => {
    dispatch(deleteMediation());
    axios
      .delete(`${constants.url}/mediations/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(deleteMediationSuccess()))
      .catch(err => dispatch(deleteMediationFailure(err)))
  }
}
