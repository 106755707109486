import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Row, Col, Button, Image, Modal, Form } from 'react-bootstrap';
import { Field, reduxForm } from "redux-form";
import { addExpertise, modifyExpertise, fetchExpertises, removeExpertise } from 'redux/actions/expertise';
import Loader from "components/ui/Loader";
import ConfirmationModal from 'components/ui/ConfirmationModal';
import { isExpertiseLoading } from 'redux/selectors';
import DeleteImage from 'images/DeleteWhite.png';
import EditImage from 'images/EditWhite.png';
import { reduxFormInput } from 'components/form/ReduxForm';
import { required } from 'util/validationUtil';
import EditExpertise from 'containers/modal/EditExpertise';

function ManageExpertises(props){
  const [modalShow, setModalShow] = useState(false);
  const [expertiseShow, setExpertiseShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [expertiseId, setExpertiseId] = useState(0);

  const { handleSubmit, isLoading, expertises, expertise, isDeleted } = props;

  const initialValues = expertises.find(expertise => expertise.id === expertiseId);

  useEffect(() => {
    props.fetchExpertises();
  }, [expertise, isDeleted])

  const editExpertise = useCallback(id => {
    setExpertiseId(id);
    setEditShow(true);
  }, [initialValues]);

  const canDelete = useCallback( id => {
    setModalShow(true);
    setExpertiseId(id);
  }, []);

  const confirmDeletion = useCallback( id => {
    setModalShow(false);
    props.removeExpertise(id);
  }, []);

  const createExpertise = useCallback( () => {
    setExpertiseShow(true);
  }, []);

  const submitFn = useCallback( data => {
    props.addExpertise(data);
    setExpertiseShow(false);
  }, []);

  const clearFields = useCallback( () => {
    setExpertiseShow(false);
    props.dispatch(reset('createExpertise'));
  }, []);

  const editSubmit = useCallback( data => {
    props.modifyExpertise(data.id, data);
    setEditShow(false);
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: '',
            accessor: 'id',
            Cell: ({ cell: { value } }) => <EditCell id={value} delete={canDelete} edit={editExpertise} />
          },
          {
            Header: 'Expertise Name',
            accessor: 'name'
          }
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Manage Expertise</h3>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={expertises}
              tableHeader={false} />
          </div>
          <Spacer size={3} />
          <Button className="btn-second-alt" variant="secondary-alt" onClick={createExpertise} >
            Add New Expertise
          </Button>
          <AddExpertise
            show={expertiseShow}
            onHide={clearFields}
            onSubmit={handleSubmit(submitFn)}
          />
          <EditExpertise
            show={editShow}
            onHide={() => setEditShow(false)}
            update={editSubmit}
            initialValues={initialValues}
          />
          <ConfirmationModal
            show={modalShow}
            id={expertiseId}
            onHide={() => setModalShow(false)}
            onConfirm={confirmDeletion}
          />
        </Container>
      </div>
    </>
  )
}

const EditCell = (props) => {
  return(
    <>
      <Image className="editdelete" src={EditImage} onClick={() => props.edit(props.id)} />
      <Image className="editdelete" src={DeleteImage} onClick={() => props.delete(props.id)} />
    </>
  )
}

const AddExpertise = (props) => {
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Add New Expertise</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container fluid>
            <Spacer size={3} />
            <Form id="createExpertiseUI" className="w-100" >
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      Name:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="name" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={2} />
                  <Button type="submit" className="primary" variant="primary-alt" size="sm">
                    Save
                  </Button>
                  <Spacer size={2} />
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => {
  const isLoading = isExpertiseLoading(state);
  const expertises = state.expertiseReducer.expertises;
  const expertise = state.expertiseReducer.expertise;
  const isDeleted = state.expertiseReducer.callSucessful.includes('DELETE_EXPERTISE');
  return { isLoading, expertises, expertise, isDeleted }
}

const connectedReduxForm = reduxForm({
  form: 'createExpertise',
  enableReinitialize: true,
})(ManageExpertises);


export default connect(
  mapStateToProps,
  {
    fetchExpertises,
    removeExpertise,
    addExpertise,
    modifyExpertise
  }
)(connectedReduxForm)
