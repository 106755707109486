import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmationModal(props) {
  return (
    <Modal className="body-black"
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Confirm Deletion</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
            Delete this item?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={() => props.onHide()}> Close </Button>
        <Button size="sm" onClick={() => props.onConfirm(props.id)}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;