import React from 'react';
import { useTable } from 'react-table';

const Table = ({ columns, data, tableHeader }) => {
  const {
    headerGroups,
    getTableProps,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    }
  )

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          { tableHeader && <tr>
            {headerGroups[0].headers.map((column, k) => (
              <th className="red-th" key={k} {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr> }
          <tr>
            {headerGroups[1].headers.map((column, k) => (
              <th key={k} {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return <td key={j} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default Table;