import React from 'react';

export const Expertise = ({ values }) => {
  return(
    <>
      {values.map((expertise, idx) => {
        return(
          <span key={idx} className="badge">
            {expertise.expertise.name}
          </span>
        )
      })}
    </>
  )
}
