import React, { useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Button } from 'react-bootstrap';
import { fetchDeclinedMediations } from 'redux/actions/mediation';
import { fetchDeclinedMediationsReport } from 'redux/actions/report';
import moment from 'moment';
import Loader from "components/ui/Loader";
import { Certification } from 'components/ui/Certification';

function DeclinedMediations(props){
  const { isLoading, declinedMediations } = props;

  const allDeclinedMediations = [];
  for (let i=0; i<declinedMediations.length; i++) {
    for (let j = 0; j < declinedMediations[i].mm_status.length; j++) {
      const element = declinedMediations[i].mm_status[j];
      const mediation = {
        id: declinedMediations[i].id,
        adr_case_number: declinedMediations[i].adr_case_number,
        mediation_date: declinedMediations[i].mediation_date,
        mediation_time: declinedMediations[i].mediation_time,
        certificates: declinedMediations[i].certificates,
        mm_status: [element]
      };
      allDeclinedMediations.push(mediation);
    }
  }

  useEffect(() => {
    props.fetchDeclinedMediations();
  }, []);

  const getReport = useCallback(() => {
    props.fetchDeclinedMediationsReport();
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'ADR Case Number',
            accessor: 'adr_case_number',
          },
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('hh:mm a');
            }
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Mediator',
            accessor: 'mm_status[0].mediators',
            Cell: ({ cell: { value }  }) => {
              if (value)
                return value.first_name + ' ' + value.last_name;
              return '';
            }
          },
          {
            Header: 'Phone',
            accessor: 'mm_status[0].mediators.phone_number',
            Cell: ({ cell: { value }  }) => {
              if(value)
                return value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
              return ''
            }
          },
          {
            Header: 'Comments',
            accessor: 'mm_status[0].decline_reason'
          }
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Declined Mediations</h3>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={allDeclinedMediations}
              tableHeader={false} />
          </div>
          <Spacer size={10} />
          <Button className="btn-second-alt" variant="secondary-alt" onClick={getReport} >
            Export
          </Button>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = state.mediationReducer.isLoading.includes('GET_DECLINED_MEDIATIONS');
  const declinedMediations = state.mediationReducer.declinedMediations;
  return { isLoading, declinedMediations }
}

export default connect(
  mapStateToProps,
  {
    fetchDeclinedMediations,
    fetchDeclinedMediationsReport
  }
)(DeclinedMediations)
