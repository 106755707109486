import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import Loader from "components/ui/Loader";
import { Certification } from 'components/ui/Certification';
import { mmStatusUpdate } from 'redux/actions/mediatorMediationStatus';
import { fetchMAssignedMediations, fetchMConfirmedDeclinedMediations } from 'redux/actions/mediation';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

function MediatorMediations(props) {
  const mediatorId = getValueFromLocal(constants['USER_ID']);
  const [modalShow, setModalShow] = useState(false);
  const [checkedMediation, setCheckedMediation] = useState(0);
  const [declineReasoning, setDeclineReasoning] = useState('');

  const { isLoading, assignedMediations, confirmedDeclinedMediations, mmStatus } = props;

  useEffect(() => {
    props.fetchMAssignedMediations(mediatorId);
    props.fetchMConfirmedDeclinedMediations(mediatorId);
  }, [mmStatus]);

  const updateSelectedMediation = useCallback( id => {
    if(checkedMediation === id) {
      setCheckedMediation(0);
    } else {
      setCheckedMediation(id);
    }
  }, [checkedMediation]);

  const confirmMediation = useCallback( () => {
    props.mmStatusUpdate(checkedMediation, 'Confirmed', '');
    setCheckedMediation(0);
  }, [checkedMediation]);

  const declineMediation = useCallback( () => {
    setModalShow(true);
  }, [checkedMediation]);

  const declineReason = useCallback( (reason) => {
    setDeclineReasoning(reason);
  }, []);

  const confirmDecline = useCallback( () => {
    setModalShow(false);
    props.mmStatusUpdate(checkedMediation, 'Declined', declineReasoning);
    setCheckedMediation(0);
  }, [checkedMediation, declineReasoning]);

  const assignedColumns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Select',
            accessor: 'mm_status[0].id',
            Cell: ({cell: { value }}) => <CheckMediation id={value} checkedId={checkedMediation} check={updateSelectedMediation}/>
          },
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('hh:mm a');
            }
          },
          {
            Header: 'Case Number',
            accessor: 'adr_case_number'
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
        ],
      },
    ],
    [checkedMediation]
  )

  const confirmDeclinedColumns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('hh:mm a');
            }
          },
          {
            Header: 'Case Number',
            accessor: 'adr_case_number'
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Status',
            accessor: 'mm_status[0].status'
          },
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Assigned Mediations</h3>
          <Spacer size={1} />
          <div className="table-responsive">
            <Table 
              columns={assignedColumns} 
              data={assignedMediations}
              tableHeader={false} />
            <Spacer size={10} />
            <Button className="btn-save" variant="primary-sm" size="sm" onClick={confirmMediation} disabled={checkedMediation==0} >
              Confirm
            </Button>
            <Button className="btn-save" variant="primary-sm" size="sm" onClick={declineMediation} disabled={checkedMediation==0} >
              Decline
            </Button>
            <Spacer size={10} />
            <h3>Confirmed / Declined Mediations</h3>
            <Spacer size={1} />
            <Table 
              columns={confirmDeclinedColumns} 
              data={confirmedDeclinedMediations}
              tableHeader={false} />
          </div>
          <ConfirmationModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            decline_reason={declineReason}
            onConfirm={confirmDecline}
          />
        </Container>
      </div>
    </>
  )
}

const CheckMediation = (props) => {
  return(
    <>
      <input type="checkbox" id="mediation" checked={props.id === props.checkedId} onChange={() => props.check(props.id)}/>
    </>
  )
}

const ConfirmationModal = (props) => {
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Decline Reason</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please tell us why you declined to attend this mediation</p>
        <textarea onChange={(e) => props.decline_reason(e.target.value)} className="form-control" ></textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" onClick={() => props.onConfirm()}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = state => {
  const isLoading = state.mediationReducer.isLoading.includes('GET_MEDIATOR_ASSIGNED_MEDIATIONS')
    || state.mediationReducer.isLoading.includes('GET_MCONFIRMED_DECLINED_MEDIATIONS')
    || state.mmStatusReducer.isLoading.includes('MEDIATOR_MEDIATION_UPDATE');
  const assignedMediations = state.mediationReducer.mAssignedMediations;
  const confirmedDeclinedMediations = state.mediationReducer.mConfirmedDeclinedMediations;
  const mmStatus = state.mmStatusReducer.mmStatus;
  return { isLoading, assignedMediations, confirmedDeclinedMediations, mmStatus }
}

export default connect(
  mapStateToProps,
  {
    mmStatusUpdate,
    fetchMAssignedMediations,
    fetchMConfirmedDeclinedMediations
  }
)(MediatorMediations)