import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Spacer from 'components/layout/Spacer';
import { Card, Container, Row, Form, Col, Button } from 'react-bootstrap';
import { fetchCertifications } from 'redux/actions/certification';
import { fetchExpertises } from 'redux/actions/expertise';
import { fetchMediation } from 'redux/actions/mediation';
import { addMediation } from 'redux/actions/mediation';
import { isCertificationLoading, isExpertiseLoading } from 'redux/selectors';
import Loader from "components/ui/Loader";
import CheckboxGroup from 'components/form/CheckboxGroup';
import { required } from 'util/validationUtil';
import { reduxFormInput, reduxWeekDateInput, reduxTimeInput } from 'components/form/ReduxForm';
import moment from 'moment';

const CreateNewMediation = (props) => {
  const { handleSubmit, isLoading, certifications, expertises, mediation } = props;

  if(mediation && mediation !== '') {
    props.history.push('/admin/manage/mediations');
  }

  useEffect(() => {
    props.fetchCertifications();
    props.fetchExpertises();
  }, [])

  const form_certifications = [];
  certifications.forEach( certificate => 
    form_certifications.push({ label: certificate.certification_type, value: certificate.certification_type })
  );

  const form_expertises = [];
  expertises.forEach( expertise => 
    form_expertises.push({ label: expertise.name, value: expertise.name })
  );

  const handleDateChangeRaw = useCallback(e => {
    e.preventDefault();
  },[]);

  const submitFn = useCallback( data => {
    data.mediation_date = moment(data.med_date).format('YYYY/MM/DD');
    if(data.certifications) {
      const temp_certificates = [];
      data.certifications.forEach(certificate => {
        const temp = certifications.find(cert =>  cert.certification_type === certificate );
        if (temp) temp_certificates.push(temp.id);
      });
      data.certification_ids = temp_certificates.join();
    }
    if(data.expertizes) {
      const temp_expertises = [];
      data.expertizes.forEach(expertise => {
        const temp = expertises.find(expert => expert.name === expertise);
        if (temp) temp_expertises.push(temp.id);
      });
      data.expertise_ids = temp_expertises.join();
    }
    props.addMediation(data);

  }, [certifications, expertises]);

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>New Mediation</h3>
          <Spacer size={3} />
          <Form id="createUI" className="w-100" onSubmit={handleSubmit(submitFn)}>
            <Row>
              <Col xs="12" sm="9">
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="4">
                    ADR Case Number:
                  </Form.Label>
                  <Col xs="12" sm="8">
                    <Field 
                      className="form-control" 
                      component={reduxFormInput}
                      validate={[required]}
                      name="adr_case_number" 
                      type="text" 
                      placeholder="ADR case number" 
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="4">
                    Date:
                  </Form.Label>
                  <Col xs="12" sm="8">
                    <Field 
                      className="form-control" 
                      component={reduxWeekDateInput}
                      validate={[required]}
                      name="med_date" 
                      onChangeRaw={handleDateChangeRaw}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="4">
                        Time:
                  </Form.Label>
                  <Col xs="12" sm="8">
                    <Field 
                      className="form-control" 
                      component={reduxTimeInput}
                      validate={[required]}
                      name="mediation_time"
                      type="time"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Col xs="12" sm="4"></Col>
                  <Col xs="12" sm="8">
                    <Card>
                      <Card.Header>
                        <Card.Title>Certifications</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Form.Group>
                          <CheckboxGroup name="certifications" options={form_certifications} />
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Col xs="12" sm="4"></Col>
                  <Col xs="12" sm="8">
                    <Card>
                      <Card.Header>
                        <Card.Title>Areas of Expertise</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <CheckboxGroup name="expertizes" options={form_expertises} />
                      </Card.Body>
                    </Card>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="12" sm="4">
                <Spacer size={20} />
                <Button type="submit" variant="primary-alt" size="lg">
                      Save
                </Button>
                <Spacer size={20} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = isCertificationLoading(state) 
    || isExpertiseLoading(state) 
    || state.mediationReducer.isLoading.includes("CREATE_MEDIATION")
    || state.mediationReducer.isLoading.includes("GET_MEDIATION");
  const certifications = state.certificationReducer.certifications;
  const expertises = state.expertiseReducer.expertises;
  const mediation = state.mediationReducer.mediation;
  return { isLoading, certifications, expertises, mediation }
}

const connectedReduxForm = reduxForm({
  form: 'createMediation',
  enableReinitialize: true
})(CreateNewMediation);

export default connect(
  mapStateToProps,
  {
    fetchCertifications,
    fetchExpertises,
    fetchMediation,
    addMediation
  })(connectedReduxForm);
