import { actionTypes } from './actionTypes';
import axios from "axios";
import constants from "config/constants";
import { getValueFromLocal } from 'util/storageUtil';

export function saveMediationMediator() {
  return {
    type: actionTypes.SAVE_MEDIATION_MEDIATOR
  }
}

export function saveMediationMediatorSuccess(mmStatus) {
  return {
    type: actionTypes.SAVE_MEDIATION_MEDIATOR_SUCCESS,
    payload: mmStatus
  }
}

export function saveMediationMediatorFailure(error) {
  return {
    type: actionTypes.SAVE_MEDIATION_MEDIATOR_FAILURE,
    payload: error
  }
}

export function updateMediationMediator() {
  return {
    type: actionTypes.UPDATE_MEDIATION_MEDIATOR
  }
}

export function updateMediationMediatorSuccess(mmStatus) {
  return {
    type: actionTypes.UPDATE_MEDIATION_MEDIATOR_SUCCESS,
    payload: mmStatus
  }
}

export function updateMediationMediatorFailure(error) {
  return {
    type: actionTypes.UPDATE_MEDIATION_MEDIATOR_FAILURE,
    payload: error
  }
}

export function mediatorMediationUpdate() {
  return {
    type: actionTypes.MEDIATOR_MEDIATION_UPDATE
  }
}

export function mediatorMediationUpdateSuccess(mmStatus) {
  return {
    type: actionTypes.MEDIATOR_MEDIATION_UPDATE_SUCCESS,
    payload: mmStatus
  }
}

export function mediatorMediationUpdateFailure(error) {
  return {
    type: actionTypes.MEDIATOR_MEDIATION_UPDATE_FAILURE,
    payload: error
  }
}

export function notifyMediator() {
  return {
    type: actionTypes.NOTIFY_MEDIATOR
  }
}

export function notifyMediatorSuccess(mmStatus) {
  return {
    type: actionTypes.NOTIFY_MEDIATOR_SUCCESS,
    payload: mmStatus
  }
}

export function notifyMediatorFailure(error) {
  return {
    type: actionTypes.NOTIFY_MEDIATOR_FAILURE,
    payload: error
  }
}

export function cancelMediationMediator() {
  return {
    type: actionTypes.CANCEL_MEDIATION_MEDIATOR
  }
}

export function cancelMediationMediatorSuccess() {
  return {
    type: actionTypes.CANCEL_MEDIATION_MEDIATOR_SUCCESS
  }
}

export function cancelMediationMediatorFailure(error) {
  return {
    type: actionTypes.CANCEL_MEDIATION_MEDIATOR_FAILURE,
    payload: error
  }
}

export const saveMMStatus = (mediation_id, mediator_id, bypass_reason) => {
  return dispatch => {
    dispatch(saveMediationMediator());
    axios
      .post(`${constants.url}/mmstatus`, {
        mediation_id,
        mediator_id,
        status: 'New',
        bypass_reason
      }, { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(saveMediationMediatorSuccess(res.data)))
      .catch(err => dispatch(saveMediationMediatorFailure(err)));
  };
};

export const updateMMStatus = (id, mediation_id, mediator_id, bypass_reason) => {
  return dispatch => {
    dispatch(updateMediationMediator());
    axios
      .post(`${constants.url}/mmstatus/recreate/${id}`, {
        mediation_id,
        mediator_id,
        status: 'New',
        bypass_reason
      }, { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateMediationMediatorSuccess(res.data)))
      .catch(err => dispatch(updateMediationMediatorFailure(err)));
  };
};

export const mmStatusUpdate = (id, status, decline_reason) => {
  return dispatch => {
    dispatch(mediatorMediationUpdate());
    axios
      .put(`${constants.url}/mmstatus/confirmdecline/${id}`, {
        status,
        decline_reason
      }, { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(mediatorMediationUpdateSuccess(res.data)))
      .catch(err => dispatch(mediatorMediationUpdateFailure(err)));
  };
};

export const notifyMediationMediator = (id) => {
  return dispatch => {
    dispatch(notifyMediator());
    axios
      .put(`${constants.url}/mmstatus/notify/${id}`, {},
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(notifyMediatorSuccess(res.data)))
      .catch(err => dispatch(notifyMediatorFailure(err)));
  };
};

export const cancelConfirmedMediation = (mms_id) => {
  return dispatch => {
    dispatch(cancelMediationMediator());
    axios
      .delete(`${constants.url}/mmstatus/cancel/${mms_id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(cancelMediationMediatorSuccess(res)))
      .catch(err => dispatch(cancelMediationMediatorFailure(err)));
  };
};
