import React, { useEffect, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Spacer from 'components/layout/Spacer';
import { Card, Container, Row, Form, Col, Button } from 'react-bootstrap';
import { fetchCertifications } from 'redux/actions/certification';
import { fetchExpertises } from 'redux/actions/expertise';
import { fetchMediation } from 'redux/actions/mediation';
import { cancelConfirmedMediation } from 'redux/actions/mediatorMediationStatus';
import { modifyMediation } from 'redux/actions/mediation';
import { isCertificationLoading, isExpertiseLoading } from 'redux/selectors';
import Loader from "components/ui/Loader";
import CheckboxGroup from 'components/form/CheckboxGroup';
import { required } from 'util/validationUtil';
import { reduxFormInput, reduxDateInput, reduxTimeInput } from 'components/form/ReduxForm';
import moment from 'moment';

const EditMediation = (props) => {
  let query = useQuery();
  const mediationId = query.get("id");

  const { handleSubmit, isLoading, certifications, expertises, mediation, initialValues, mediatorRemoved } = props;

  if(mediation && mediation !== '') {
    props.history.push('/admin/manage/mediations');
  }

  useEffect(() => {
    props.fetchCertifications();
    props.fetchExpertises();
    props.fetchMediation(mediationId);
  }, [])

  useEffect(() => {
    if(mediatorRemoved) {
      props.fetchMediation(mediationId);
      props.dispatch({ type: 'MEDIATOR_REMOVED' });
    }
  }, [mediatorRemoved])

  const form_certifications = [];
  certifications.forEach( certificate => 
    form_certifications.push({ label: certificate.certification_type, value: certificate.certification_type })
  );

  const form_expertises = [];
  expertises.forEach( expertise => 
    form_expertises.push({ label: expertise.name, value: expertise.name })
  );

  if(mediationId && initialValues) {
    if(initialValues.mm_status.length > 0) {
      const mediator = initialValues.mm_status[0].mediators;
      initialValues.mediator_name = mediator.first_name + " " + mediator.last_name;
    }
    console.log(initialValues.mediator_name);
    // initialValues.med_date =  new Date(initialValues.mediation_date);
    // initialValues.mediation_time = moment(initialValues.mediation_time, 'HH:mm:ss').format('hh:mm A');
    const certificationsArray = [];
    initialValues.certificates.forEach(c => {
      if(c.certification) certificationsArray.push(c.certification.certification_type);
    });
    initialValues.certifications = certificationsArray;
  
    const expertisesArray = [];
    initialValues.expertises.forEach(e => {
      if(e.expertise) expertisesArray.push(e.expertise.name);
    });
    initialValues.expertizes = expertisesArray;
  }

  const handleDateChangeRaw = useCallback(e => {
    e.preventDefault();
  },[]);

  const removeMediator = useCallback(() => {
    props.cancelConfirmedMediation(initialValues.mm_status[0].id);
  }, [initialValues]);

  const submitFn = useCallback( data => {
    data.mediation_date = moment(data.mediation_date).format('YYYY/MM/DD');
    if(data.certifications) {
      const temp_certificates = [];
      data.certifications.forEach(certificate => {
        const temp = certifications.find(cert =>  cert.certification_type === certificate );
        if (temp) temp_certificates.push(temp.id);
      });
      data.certification_ids = temp_certificates.join();
    }
    if(data.expertizes) {
      const temp_expertises = [];
      data.expertizes.forEach(expertise => {
        const temp = expertises.find(expert => expert.name === expertise);
        if (temp) temp_expertises.push(temp.id);
      });
      data.expertise_ids = temp_expertises.join();
    }
    props.modifyMediation(mediationId, data);

  }, [certifications, expertises]);

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Edit Mediation</h3>
          <Spacer size={3} />
          <Form id="createUI" className="w-100" onSubmit={handleSubmit(submitFn)}>
            <Row>
              <Col xs="12" sm="9">
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="4">
                    ADR Case Number:
                  </Form.Label>
                  <Col xs="12" sm="8">
                    <Field 
                      className="form-control" 
                      component={reduxFormInput}
                      validate={[required]}
                      name="adr_case_number" 
                      type="text" 
                      placeholder="ADR case number" 
                    />
                  </Col>
                </Form.Group>
                {initialValues && initialValues.mm_status.length > 0 && 
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="4">
                    Mediator:
                  </Form.Label>
                  <Col xs="12" sm="6">
                    <Field 
                      className="form-control" 
                      component="input"
                      name="mediator_name"
                      type="text" 
                      disabled={true}
                    />
                  </Col>
                  <Col xs="12" sm="2">
                    <Button onClick={removeMediator}> 
                      Remove
                    </Button>
                  </Col>
                </Form.Group>
                }
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="4">
                    Date:
                  </Form.Label>
                  <Col xs="12" sm="8">
                    <Field 
                      className="form-control" 
                      component={reduxDateInput}
                      validate={[required]}
                      name="mediation_date"
                      onChangeRaw={handleDateChangeRaw}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="4">
                        Time:
                  </Form.Label>
                  <Col xs="12" sm="8">
                    <Field 
                      className="form-control" 
                      component={reduxTimeInput}
                      validate={[required]}
                      name="mediation_time"
                      type="time"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Col xs="12" sm="4"></Col>
                  <Col xs="12" sm="8">
                    <Card>
                      <Card.Header>
                        <Card.Title>Certifications</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Form.Group>
                          <CheckboxGroup name="certifications" options={form_certifications} />
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Col xs="12" sm="4"></Col>
                  <Col xs="12" sm="8">
                    <Card>
                      <Card.Header>
                        <Card.Title>Areas of Expertise</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <CheckboxGroup name="expertizes" options={form_expertises} />
                      </Card.Body>
                    </Card>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="12" sm="4">
                <Spacer size={20} />
                <Button type="submit" variant="primary-alt" size="lg">
                  Save
                </Button>
                <Spacer size={20} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapStateToProps = state => {
  const isLoading = isCertificationLoading(state) 
    || isExpertiseLoading(state) 
    || state.mediationReducer.isLoading.includes("GET_MEDIATION")
    || state.mediationReducer.isLoading.includes("UPDATE_MEDIATION")
    || state.mmStatusReducer.isLoading.includes("CANCEL_MEDIATION_MEDIATOR");
  const certifications = state.certificationReducer.certifications;
  const expertises = state.expertiseReducer.expertises;
  const mediation = state.mediationReducer.mediation;
  const initialValues = state.mediationReducer.existingMediation;
  const mediatorRemoved = state.mmStatusReducer.callSuccessful.includes("CANCEL_MEDIATION_MEDIATOR");
  return { isLoading, certifications, expertises, mediation, initialValues, mediatorRemoved }
}

const connectedReduxForm = reduxForm({
  form: 'editMediation',
  enableReinitialize: true
})(EditMediation);

export default connect(
  mapStateToProps,
  {
    fetchCertifications,
    fetchExpertises,
    fetchMediation,
    modifyMediation,
    cancelConfirmedMediation
  })(connectedReduxForm);
