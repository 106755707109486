const titles = [
  {label: "Mr.", value: "Mr."},
  {label: "Mrs.", value: "Mrs."},
  {label: "Ms.", value: "Ms."},
]

const communication_method = [
  {label: "Email", value: "Email"},
  {label: "Text message", value: "Text message"},
]

const states = [
  {label: "AL", value: "Alabama"},
  {label: "AK", value: "Alaska"},
  {label: "AS", value: "American Samoa"},
  {label: "AZ", value: "Arizona"},
  {label: "AR", value: "Arkansas"},
  {label: "CA", value: "California"},
  {label: "CO", value: "Colorado"},
  {label: "CT", value: "Connecticut"},
  {label: "DE", value: "Delaware"},
  {label: "DC", value: "District Of Columbia"},
  {label: "FM", value: "Federated States Of Micronesia"},
  {label: "FL", value: "Florida"},
  {label: "GA", value: "Georgia"},
  {label: "GU", value: "Guam"},
  {label: "HI", value: "Hawaii"},
  {label: "ID", value: "Idaho"},
  {label: "IL", value: "Illinois"},
  {label: "IN", value: "Indiana"},
  {label: "IA", value: "Iowa"},
  {label: "KS", value: "Kansas"},
  {label: "KY", value: "Kentucky"},
  {label: "LA", value: "Louisiana"},
  {label: "ME", value: "Maine"},
  {label: "MH", value: "Marshall Islands"},
  {label: "MD", value: "Maryland"},
  {label: "MA", value: "Massachusetts"},
  {label: "MI", value: "Michigan"},
  {label: "MN", value: "Minnesota"},
  {label: "MS", value: "Mississippi"},
  {label: "MO", value: "Missouri"},
  {label: "MT", value: "Montana"},
  {label: "NE", value: "Nebraska"},
  {label: "NV", value: "Nevada"},
  {label: "NH", value: "New Hampshire"},
  {label: "NJ", value: "New Jersey"},
  {label: "NM", value: "New Mexico"},
  {label: "NY", value: "New York"},
  {label: "NC", value: "North Carolina"},
  {label: "ND", value: "North Dakota"},
  {label: "MP", value: "Northern Mariana Islands"},
  {label: "OH", value: "Ohio"},
  {label: "OK", value: "Oklahoma"},
  {label: "OR", value: "Oregon"},
  {label: "PW", value: "Palau"},
  {label: "PA", value: "Pennsylvania"},
  {label: "PR", value: "Puerto Rico"},
  {label: "RI", value: "Rhode Island"},
  {label: "SC", value: "South Carolina"},
  {label: "SD", value: "South Dakota"},
  {label: "TN", value: "Tennessee"},
  {label: "TX", value: "Texas"},
  {label: "UT", value: "Utah"},
  {label: "VT", value: "Vermont"},
  {label: "VI", value: "Virgin Islands"},
  {label: "VA", value: "Virginia"},
  {label: "WA", value: "Washington"},
  {label: "WV", value: "West Virginia"},
  {label: "WI", value: "Wisconsin"},
  {label: "WY", value: "Wyoming"
  }
];

export {
  titles, 
  communication_method,
  states
}