import { actionTypes } from './actionTypes';
import axios from 'axios';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

export function createExpertise() {
  return {
    type: actionTypes.CREATE_EXPERTISE
  }
}
  
export function createExpertiseSuccess(expertise) {
  return {
    type: actionTypes.CREATE_EXPERTISE_SUCCESS,
    payload: expertise
  }
}
  
export function createExpertiseFailure(error) {
  return {
    type: actionTypes.CREATE_EXPERTISE_FAILURE,
    payload: error
  }
}

export function getExpertises() {
  return {
    type: actionTypes.GET_EXPERTISES
  }
}
      
export function getExpertisesSuccess(expertises) {
  return {
    type: actionTypes.GET_EXPERTISES_SUCCESS,
    payload: expertises
  }
}
      
export function getExpertisesFailure(error) {
  return {
    type: actionTypes.GET_EXPERTISES_FAILURE,
    payload: error
  }
}

export function updateExpertise() {
  return {
    type: actionTypes.UPDATE_EXPERTISE
  }
}
  
export function updateExpertiseSuccess(expertise) {
  return {
    type: actionTypes.UPDATE_EXPERTISE_SUCCESS,
    payload: expertise
  }
}
  
export function updateExpertiseFailure(error) {
  return {
    type: actionTypes.UPDATE_EXPERTISE_FAILURE,
    payload: error
  }
}

export function deleteExpertise() {
  return {
    type: actionTypes.DELETE_EXPERTISE
  }
}
  
export function deleteExpertiseSuccess() {
  return {
    type: actionTypes.DELETE_EXPERTISE_SUCCESS
  }
}
  
export function deleteExpertiseFailure(error) {
  return {
    type: actionTypes.DELETE_EXPERTISE_FAILURE,
    payload: error
  }
}

export const addExpertise = (expertise) => {
  return dispatch => {
    dispatch(createExpertise());
    axios
      .post(`${constants.url}/expertises`, expertise,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(createExpertiseSuccess(res)))
      .catch(err => dispatch(createExpertiseFailure(err)))
  }
}


export const fetchExpertises = () => {
  return dispatch => {
    dispatch(getExpertises());
    axios
      .get(`${constants.url}/expertises`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getExpertisesSuccess(res.data)))
      .catch(err => dispatch(getExpertisesFailure(err)))
  }
}

export const modifyExpertise = (id, expertise) => {
  return dispatch => {
    dispatch(updateExpertise());
    axios
      .put(`${constants.url}/expertises/${id}`, expertise,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateExpertiseSuccess(res.data)))
      .catch(err => dispatch(updateExpertiseFailure(err)))
  }
}

export const removeExpertise = (id) => {
  return dispatch => {
    dispatch(deleteExpertise());
    axios
      .delete(`${constants.url}/expertises/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(deleteExpertiseSuccess()))
      .catch(err => dispatch(deleteExpertiseFailure(err)))
  }
}
