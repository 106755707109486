import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isLoading: [],
  loadingFailed: [],
  callSuccessful: [],
  existingMediation: '',
  mediation: '',
  mediations: [],
  mediationsCount: 0,
  newMediations: [],
  notifiedMediations: [],
  assignedMediations: [],
  declinedMediations: [],
  mAssignedMediations: [],
  mConfirmedDeclinedMediations: [],
  upcomingMediations: [],
  recentMediations: [],
  rate: {},
  report: []
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CREATE_MEDIATION:
    return {
      ...state,
      isLoading: [...state.isLoading, "CREATE_MEDIATION"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATION'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATION'})
    }
  case actionTypes.CREATE_MEDIATION_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATION'}),
      callSuccessful: [...state.isLoading, "CREATE_MEDIATION"],
      mediation: action.payload.data
    }
  case actionTypes.CREATE_MEDIATION_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATION'}),
      loadingFailed: [...state.isLoading, "CREATE_MEDIATION"],
      error: action.payload
    }
  case actionTypes.GET_MEDIATION: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_MEDIATION"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION'})
    }
  case actionTypes.GET_MEDIATION_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION'}),
      callSuccessful: [...state.isLoading, "GET_MEDIATION"],
      existingMediation: action.payload.data
    }
  case actionTypes.GET_MEDIATION_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION'}),
      loadingFailed: [...state.isLoading, "GET_MEDIATION"],
      error: action.payload
    }
  case actionTypes.GET_MEDIATIONS: 
    return {
      ...state,
      mediation: '',
      existingMediation: '',
      isLoading: [...state.isLoading, "GET_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATIONS' && value !== 'DELETE_MEDIATION'})
    }
  case actionTypes.GET_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_MEDIATIONS"],
      mediations: action.payload.data,
      mediationsCount: action.payload.count
    }
  case actionTypes.GET_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_NEW_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_NEW_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_NEW_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_NEW_MEDIATIONS'})
    }
  case actionTypes.GET_NEW_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_NEW_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_NEW_MEDIATIONS"],
      newMediations: action.payload.data
    }
  case actionTypes.GET_NEW_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_NEW_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_NEW_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_NOTIFIED_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_NOTIFIED_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_NOTIFIED_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_NOTIFIED_MEDIATIONS'})
    }
  case actionTypes.GET_NOTIFIED_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_NOTIFIED_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_NOTIFIED_MEDIATIONS"],
      notifiedMediations: action.payload.data
    }
  case actionTypes.GET_NOTIFIED_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_NOTIFIED_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_NOTIFIED_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_ASSIGNED_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_ASSIGNED_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_ASSIGNED_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_ASSIGNED_MEDIATIONS'})
    }
  case actionTypes.GET_ASSIGNED_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_ASSIGNED_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_ASSIGNED_MEDIATIONS"],
      assignedMediations: action.payload.data
    }
  case actionTypes.GET_ASSIGNED_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_ASSIGNED_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_ASSIGNED_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_DECLINED_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_DECLINED_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_DECLINED_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_DECLINED_MEDIATIONS'})
    }
  case actionTypes.GET_DECLINED_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_DECLINED_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_DECLINED_MEDIATIONS"],
      declinedMediations: action.payload.data
    }
  case actionTypes.GET_DECLINED_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_DECLINED_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_DECLINED_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_MEDIATOR_ASSIGNED_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_MEDIATOR_ASSIGNED_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_ASSIGNED_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_ASSIGNED_MEDIATIONS'})
    }
  case actionTypes.GET_MEDIATOR_ASSIGNED_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_ASSIGNED_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_MEDIATOR_ASSIGNED_MEDIATIONS"],
      mAssignedMediations: action.payload.data
    }
  case actionTypes.GET_MEDIATOR_ASSIGNED_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_ASSIGNED_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_MEDIATOR_ASSIGNED_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_MCONFIRMED_DECLINED_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_MCONFIRMED_DECLINED_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MCONFIRMED_DECLINED_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MCONFIRMED_DECLINED_MEDIATIONS'})
    }
  case actionTypes.GET_MCONFIRMED_DECLINED_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MCONFIRMED_DECLINED_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_MCONFIRMED_DECLINED_MEDIATIONS"],
      mConfirmedDeclinedMediations: action.payload.data
    }
  case actionTypes.GET_MCONFIRMED_DECLINED_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MCONFIRMED_DECLINED_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_MCONFIRMED_DECLINED_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_UPCOMING_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_UPCOMING_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_UPCOMING_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_UPCOMING_MEDIATIONS'})
    }
  case actionTypes.GET_UPCOMING_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_UPCOMING_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_UPCOMING_MEDIATIONS"],
      upcomingMediations: action.payload.data
    }
  case actionTypes.GET_UPCOMING_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_UPCOMING_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_UPCOMING_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_RECENT_MEDIATIONS: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_RECENT_MEDIATIONS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_RECENT_MEDIATIONS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_RECENT_MEDIATIONS'})
    }
  case actionTypes.GET_RECENT_MEDIATIONS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_RECENT_MEDIATIONS'}),
      callSuccessful: [...state.isLoading, "GET_RECENT_MEDIATIONS"],
      recentMediations: action.payload.data
    }
  case actionTypes.GET_RECENT_MEDIATIONS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_RECENT_MEDIATIONS'}),
      loadingFailed: [...state.isLoading, "GET_RECENT_MEDIATIONS"],
      error: action.payload
    }
  case actionTypes.GET_MEDIATION_REPORT: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_MEDIATION_REPORT"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION_REPORT'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION_REPORT'})
    }
  case actionTypes.GET_MEDIATION_REPORT_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION_REPORT'}),
      callSuccessful: [...state.isLoading, "GET_MEDIATION_REPORT"],
      report: action.payload.data
    }
  case actionTypes.GET_MEDIATION_REPORT_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATION_REPORT'}),
      loadingFailed: [...state.isLoading, "GET_MEDIATION_REPORT"],
      error: action.payload
    }
  case actionTypes.GET_CONFIRMATION_RATE: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_CONFIRMATION_RATE"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_CONFIRMATION_RATE'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_CONFIRMATION_RATE'})
    }
  case actionTypes.GET_CONFIRMATION_RATE_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_CONFIRMATION_RATE'}),
      callSuccessful: [...state.isLoading, "GET_CONFIRMATION_RATE"],
      rate: action.payload.data
    }
  case actionTypes.GET_CONFIRMATION_RATE_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_CONFIRMATION_RATE'}),
      loadingFailed: [...state.isLoading, "GET_CONFIRMATION_RATE"],
      error: action.payload
    }
  case actionTypes.UPDATE_MEDIATION:
    return {
      ...state,
      isLoading: [...state.isLoading, "UPDATE_MEDIATION"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION'})
    }
  case actionTypes.UPDATE_MEDIATION_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION'}),
      callSuccessful: [...state.isLoading, "UPDATE_MEDIATION"],
      mediation: action.payload.data
    }
  case actionTypes.UPDATE_MEDIATION_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION'}),
      loadingFailed: [...state.isLoading, "UPDATE_MEDIATION"],
      error: action.payload
    }
  case actionTypes.DELETE_MEDIATION:
    return {
      ...state,
      isLoading: [...state.isLoading, "DELETE_MEDIATION"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATION'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATION'})
    }
  case actionTypes.DELETE_MEDIATION_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATION'}),
      callSuccessful: [...state.isLoading, "DELETE_MEDIATION"]
    }
  case actionTypes.DELETE_MEDIATION_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATION'}),
      loadingFailed: [...state.isLoading, "DELETE_MEDIATION"]
    }
  default: 
    return state
  }
}