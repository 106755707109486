import React from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import { Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { checkAuthentication } from 'redux/selectors';
import Loader from "components/ui/Loader";
import moment from 'moment';
import Calendar from './Calendar';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';
import {mediatorAvailabilityApiCall,mediatorAvailability,deleteAvailability, mediatorEditApiCall} from 'redux/actions/mediatorAvailability';

class MediatorAvailability extends React.Component {
  constructor(){
    super();
    this.state={
      monthSelected:moment().format('MMM'),
      currentYear:moment().format('YYYY'),
      presentYear:moment().format('YYYY'),
      currentMonth:'',
      arrowClicked:false,
      showLoader:false,
      showCalled:false
    }
  }

  componentDidMount(){
    this.loadMediatorAvailability()
  }

  componentDidUpdate(prevProps){
    if(prevProps.mediatorAvailabilityResponse.data!=this.props.mediatorAvailabilityResponse.data){
      this.setState({
        showCalled:true,
        showLoader:false,
      })
    }
  }

  callback=(value)=>{
    if(value){
      this.setState({
        showLoader:false
      })
      this.loadMediatorAvailability()
    }
  }
  newMediatorAvailability = (date,comments,editApi) => {
    let mediatorPlan
    this.setState({
      showLoader:true
    })

    mediatorPlan={
      mediator_id:localStorage.getItem("nlasdfALSERNSDln"),
      date:date,
      comments:comments}
    if(editApi){
      let  mediatorData={
        id:editApi,
        comments:comments
      }
      this.props.mediatorEditApiCall(mediatorData,this.callback)
    }
    else{
      // console.log('mediatorAvailability.newMediatorAvailability called');
      this.props.mediatorAvailabilityApiCall(mediatorPlan,this.callback)
    }
    
  }

  loadMediatorAvailability = () =>{
    this.setState({
      showLoader:true
    })
    this.props.mediatorAvailability(localStorage.getItem("nlasdfALSERNSDln"))
  }

  monthChangedviaArrowClick = (value) =>{
    this.setState({
      arrowClicked:true,
      currentMonth:value,
      //showLoader:true,
      monthSelected:moment(value,'YYYYMMMDD').format('MMM')
    })
  }

  handleMonthSelection = (event) =>{
    this.setState({
      monthSelected:event.target.value,
      arrowClicked:true,
      currentMonth:this.state.currentYear+event.target.value+"01"
    })

  }

     handleYearSelection = (event) =>{
       this.setState({
         currentYear:event.target.value,
         arrowClicked:true,
         currentMonth:(event.target.value + this.state.monthSelected + "01")
       })
     }

     deleteAvailability=(id)=>{
       this.setState({
         showLoader:true
       })
       this.props.deleteAvailability(id, this.deleteCallback)
     }

     deleteCallback = (value) =>{
       if(value.data == 'Deleted Successfully'){
         this.setState({
           showLoader:false
         })
         this.loadMediatorAvailability()
       }
     }

     render() {
      // console.log(JSON.stringify(constants))
       const isActive = getValueFromLocal(constants.IS_ACTIVE);
      //  console.log("isActive: "+isActive)
       let selectedMonthItem=[];
       let count=0
       let loaderShow=this.state.showLoader
       const { isAuthenticated } = this.props;
       if(this.props.mediatorAvailabilityResponse.data!=undefined){
         selectedMonthItem =  this.props.mediatorAvailabilityResponse.data.map(item=>{
           if(moment(item.date).format("MMM")==this.state.monthSelected){
             count++
             return item
           }
         })}
   
       let yearArray=[]
       if(!isAuthenticated) {
         return <Redirect to='/landing' />
       }

       for(let i=1970;i<=this.state.presentYear;i++){
         yearArray.push(i);
       }
       let options=yearArray.map((data) =>
         <option 
           key={data}
           value={data}
         >
           {data}
         </option>
       );
       if((selectedMonthItem.length>0) && this.state.showCalled==false){
         loaderShow=false
       }
       return(
         <>
           <div className="fc-content">
             <Spacer size={30} />
             <Container fluid>
               <h2>Plan Availability</h2>
             </Container>
             <div className="dropdown_position">
               <p>
                 <select className="selection arrows" value={this.state.monthSelected} onChange={this.handleMonthSelection}>
                   <option value="Jan">January</option>
                   <option value="Feb">February</option>
                   <option value="Mar">March</option>
                   <option value="Apr">April</option>
                   <option value="May">May</option>
                   <option value="Jun">June</option>
                   <option value="Jul">July</option>
                   <option value="Aug">August</option>
                   <option value="Sep">September</option>
                   <option value="Oct">October</option>
                   <option value="Nov">November</option>
                   <option value="Dec">December</option>
                 </select>
&nbsp;&nbsp;
                 <select className="selectYear arrowsYear" value={this.state.currentYear} onChange={this.handleYearSelection}>
                   {options}
                 </select>
               </p>
             </div>
             {  loaderShow == true?<Loader /> :""}
             <Calendar 
               isActive={isActive}
               currentYear={this.state.currentYear}
               newMediatorAvailability={this.newMediatorAvailability} 
               selectedMonthItem={selectedMonthItem}
               monthSelected={this.state.monthSelected}
               currentMonth={this.state.currentMonth}
               monthChangedviaArrowClick={this.monthChangedviaArrowClick}
               deleteAvailability={this.deleteAvailability}/>
           </div>
         </>
       )
     }
}

const mapStateToProps = state => ({
  isAuthenticated: checkAuthentication(state),
  mediatorAvailabilityResponse: state.mediatorAvailabilityReducer.mediatorAvailabilityResponse
})
const mapDispatchToProps = {
  mediatorAvailabilityApiCall: mediatorAvailabilityApiCall,
  mediatorAvailability:mediatorAvailability,
  deleteAvailability:deleteAvailability,
  mediatorEditApiCall:mediatorEditApiCall
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediatorAvailability)