import React from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Logo from 'images/FccLogoWhite.png';
import { checkAuthentication } from 'redux/selectors';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';
import { Redirect } from 'react-router-dom';

class LandingPage extends React.Component {
  render() {
    const { isAuthenticated } = this.props;
    if(isAuthenticated) {
      const role = getValueFromLocal(constants.ROLE);
      return <Redirect to={`/${role}/panel`} />
      // this.props.history.push(`/${role}`);
    }
    return(
      <main className="fc-content" id="landing">
        <Container>
          <Row>
            <Col xs="12">
              <Spacer size={20} />
              <Image src={Logo} width="250px" height="auto" alt="Fulton County Logo" />
              <Spacer size={30} />
              <h1>Welcome to the Fulton County ADR Office Mediation Scheduler</h1>
              <Spacer size={30} />
            </Col>
            <Col xs="12" sm="6" md="5" lg="3">
              <Link to="/admin/login">
                <Button className="mb-4" variant="primary" size="lg">
                  Administrator
                </Button>
              </Link>
            </Col>
            <Col xs="12" sm="6" md="5" lg="3">
              <Link to="/mediator/login">
                <Button className="mb-4" variant="primary" size="lg">
                  Mediator
                </Button>
              </Link>
            </Col>
            <Col xs="12" sm="6" md="5" lg="3">
              <Link to="/scheduler/login">
                <Button className="mb-4" variant="primary" size="lg">
                  Scheduler
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </main>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: checkAuthentication(state)
})

export default connect(
  mapStateToProps
)(LandingPage)