import React from 'react';
import Spacer from 'components/layout/Spacer';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

function AdminPanel() {
  return(
    <>
      <div className="fc-content">
        <Spacer size={30} />
        <Container fluid>
          <Row>
            <Col xs="12" sm="12" md="12">
              <Row className="text-center">
                <Col className="dashboard-items" xs="12" lg="4">
                  <h3>Scheduling</h3>
                  <Link to="/admin/manage/mediations">
                    <Button variant="secondary">
                        Manage Mediations
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/schedule/mediations">
                    <Button variant="secondary">
                      Schedule Mediations
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/mediator/availability">
                    <Button variant="secondary">
                      View Mediator Availability
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/mediation/confirmations">
                    <Button variant="secondary">
                      View Confirmations
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/mediation/declined">
                    <Button variant="secondary">
                      View Declined
                    </Button>
                  </Link>
                  <Spacer size={30} />
                </Col>
                <Col className="dashboard-items" xs="12" lg="4">
                  <h3>Program Management</h3>
                  <Link to="/admin/manage/mediators">
                    <Button variant="secondary">
                      Manage Mediators
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/all/mediators">
                    <Button variant="secondary">
                      View All Mediators
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/mediator">
                    <Button variant="secondary">
                      Create New Mediator
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/manage/administrators">
                    <Button variant="secondary">
                      Manage Administrators
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/manage/schedulers">
                    <Button variant="secondary">
                      Manage Schedulers
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/manage/expertises">
                    <Button variant="secondary">
                      Manage Expertises
                    </Button>
                  </Link>
                  <Spacer size={5} />
                  <Link to="/admin/manage/certifications">
                    <Button variant="secondary">
                      Manage Certifications
                    </Button>
                  </Link>
                  <Spacer size={30} />
                </Col>
                <Col className="dashboard-items" xs="12" lg="4">
                  <h3></h3>
                  <Link to="/admin/activityreport">
                    <Button variant="secondary">
                      Mediator Activity Report
                    </Button>
                  </Link>
                  <Spacer size={30} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AdminPanel;