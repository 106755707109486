import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  loadingFailed: false,
  mma: [],
  mediatorAvailabilityResponse:{},
  mediatorsByMonth:[],
  mediatorList:{},
  mediatorListFailure:false,
  loading:''
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.MEDIATOR_AVAILABILITY_SUCCESS:
    return {
      ...state,
      loading:false,
      mediatorAvailabilityResponse:action.payload
    }
  case actionTypes.MEDIATORS_FOR_MONTH_SUCCESS:
    return{
      ...state,
      mediatorsByMonth:action.payload
    } 
  case actionTypes.GET_MEDIATION_MA: 
    return {
      ...state,
      isLoading: true,
      loadingFailed: false
    }
  case actionTypes.GET_MEDIATION_MA_SUCCESS:
    return {
      ...state,
      isLoading: false,
      loadingFailed: false,
      mma: action.payload.data,
    }
  case actionTypes.GET_MEDIATION_MA_FAILURE:
    return {
      ...state,
      isLoading: false,
      loadingFailed: true,
      error: action.payload
    }
  case actionTypes.GET_MEDIATOR_LIST:
    return {
      ...state,
      mediatorList:action.payload
    }
  case actionTypes.GET_MEDIATOR_LIST_FAILURE:
    return {
      ...state,
      mediatorListFailure:true
    }
  default: 
    return state
  }
}