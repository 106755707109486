import Panel from 'containers/mediator/Panel';
import MediatorProfile from 'containers/mediator/ViewProfile';
import MediatorAvailability from 'containers/mediator/MediatorAvailability';
import MediatorMediations from 'containers/mediator/MediatorMediations';
import PasswordSet from 'containers/mediator/PasswordSet';

const mediatorRoutes = [
  {
    path: "/panel",
    component: Panel,
    name: "Mediator",
    layout: "/mediator"
  },
  {
    path: "/profile",
    component: MediatorProfile,
    name: "Profile",
    layout: "/mediator"
  },
  {
    path: "/availability",
    component: MediatorAvailability,
    name: "Plan Availability",
    layout: "/mediator"
  },
  {
    path: "/mediations",
    component: MediatorMediations,
    name: "View Mediations",
    layout: "/mediator"
  },
  {
    path: "/setpassword",
    component: PasswordSet,
    name: "Set Password",
    layout: "/mediator"
  }
];

export { mediatorRoutes };
