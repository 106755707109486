import React from 'react';

export const Certification = ({ values }) => {
  return(
    <>
      {values.map((certificate, idx) => {
        return(
          <span key={idx} className="badge">
            { certificate.certification.certification_type + " " }
          </span>
        )
      })}
    </>
  )
}