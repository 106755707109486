import React from "react";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { toast } from "react-toastify";
import "./App.css";
import "styles/app.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import LandingPage from "containers/LandingPage";
import PasswordReset from "containers/PasswordReset";
import AdminLogin from "containers/login/AdminLogin";
import MediatorLogin from "containers/login/MediatorLogin";
import SchedulerLogin from "containers/login/SchedulerLogin";
import ForgotPassword from "containers/login/ForgotPassword";

import AdminDashboard from "components/layout/AdminDashboard";
import MediatorDashboard from "components/layout/MediatorDashboard";
import SchedulerDashboard from "components/layout/SchedulerDashboard";

toast.configure({
  autoClose: 6000,
  draggable: false,
  position: "bottom-left"
});

const hist = createBrowserHistory();

function App() {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/admin/reset" exact component={PasswordReset} />
        <Route path="/admin/login/forgot" exact component={ForgotPassword} />
        <Route path="/admin/login" exact component={AdminLogin} />
        <Route path="/mediator/reset" exact component={PasswordReset} />
        <Route path="/mediator/login/forgot" exact component={ForgotPassword} />
        <Route path="/mediator/login" exact component={MediatorLogin} />
        <Route path="/scheduler/reset" exact component={PasswordReset} />
        <Route path="/scheduler/login/forgot" exact component={ForgotPassword} />
        <Route path="/scheduler/login" exact component={SchedulerLogin} />
        <Route path="/admin" render={props => <AdminDashboard {...props} />} />
        <Route path="/mediator" render={props => <MediatorDashboard {...props} />} />
        <Route path="/scheduler" render={props => <SchedulerDashboard {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
