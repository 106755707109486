import { actionTypes } from './actionTypes';
import axios from 'axios';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

export function createScheduler() {
  return {
    type: actionTypes.CREATE_SCHEDULER
  }
}
  
export function createSchedulerSuccess(scheduler) {
  return {
    type: actionTypes.CREATE_SCHEDULER_SUCCESS,
    payload: scheduler
  }
}
  
export function createSchedulerFailure(error) {
  return {
    type: actionTypes.CREATE_SCHEDULER_FAILURE,
    payload: error
  }
}

export function getSchedulers() {
  return {
    type: actionTypes.GET_SCHEDULERS
  }
}
    
export function getSchedulersSuccess(schedulers) {
  return {
    type: actionTypes.GET_SCHEDULERS_SUCCESS,
    payload: schedulers
  }
}
    
export function getSchedulersFailure(error) {
  return {
    type: actionTypes.GET_SCHEDULERS_FAILURE,
    payload: error
  }
}

export function updateScheduler() {
  return {
    type: actionTypes.UPDATE_SCHEDULER
  }
}
  
export function updateSchedulerSuccess(scheduler) {
  return {
    type: actionTypes.UPDATE_SCHEDULER_SUCCESS,
    payload: scheduler
  }
}
  
export function updateSchedulerFailure(error) {
  return {
    type: actionTypes.UPDATE_SCHEDULER_FAILURE,
    payload: error
  }
}

export function updatePassword() {
  return {
    type: actionTypes.UPDATE_SCHEDULER_PASSWORD
  }
}
  
export function updatePasswordSuccess(scheduler) {
  return {
    type: actionTypes.UPDATE_SCHEDULER_PASSWORD_SUCCESS,
    payload: scheduler
  }
}
  
export function updatePasswordFailure(error) {
  return {
    type: actionTypes.UPDATE_SCHEDULER_PASSWORD_FAILURE,
    payload: error
  }
}

export function deleteScheduler() {
  return {
    type: actionTypes.DELETE_SCHEDULER
  }
}
  
export function deleteSchedulerSuccess() {
  return {
    type: actionTypes.DELETE_SCHEDULER_SUCCESS
  }
}
  
export function deleteSchedulerFailure(error) {
  return {
    type: actionTypes.DELETE_SCHEDULER_FAILURE,
    payload: error
  }
}

export function allowSchedulerPasswordChange() {
  return {
    type: actionTypes.ALLOW_SCHEDULER_PASSWORD_CHANGE
  }
}

export const addScheduler = (scheduler) => {
  return dispatch => {
    dispatch(createScheduler());
    axios
      .post(`${constants.url}/schedulers`, scheduler,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(createSchedulerSuccess(res)))
      .catch(err => dispatch(createSchedulerFailure(err)))
  }
}

export const fetchSchedulers = () => {
  return dispatch => {
    dispatch(getSchedulers());
    axios
      .get(`${constants.url}/schedulers`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getSchedulersSuccess(res.data)))
      .catch(err => dispatch(getSchedulersFailure(err)))
  }
}

export const modifyScheduler = (id, scheduler) => {
  return dispatch => {
    dispatch(updateScheduler());
    axios
      .put(`${constants.url}/schedulers/${id}`, scheduler,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateSchedulerSuccess(res.data)))
      .catch(err => dispatch(updateSchedulerFailure(err)))
  }
}

export const updateSchedulerPassword = (id, password) => {
  return dispatch => {
    dispatch(updatePassword());
    axios
      .put(`${constants.url}/schedulers/password/${id}`, password,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updatePasswordSuccess(res)))
      .catch(err => dispatch(updatePasswordFailure(err)))
  }
}

export const resetSchedulerForgotPassword = (id, password, token) => {
  return dispatch => {
    dispatch(updatePassword());
    axios
      .put(`${constants.url}/schedulers/password/${id}`, password,
        { headers: { Authorization: `Bearer ${token}` } })
      .then(res => dispatch(updatePasswordSuccess(res)))
      .catch(err => dispatch(updatePasswordFailure(err)))
  }
}

export const removeScheduler = (id) => {
  return dispatch => {
    dispatch(deleteScheduler());
    axios
      .delete(`${constants.url}/schedulers/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(deleteSchedulerSuccess()))
      .catch(err => dispatch(deleteSchedulerFailure(err)))
  }
}
