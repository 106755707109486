import { actionTypes } from './actionTypes';
import axios from "axios";
import constants from "config/constants";
import { getValueFromLocal } from 'util/storageUtil';

export const mediatorAvailability = (mediator_id) => {
 
  const config = {
    headers: { 
      Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`}
  };

  return dispatch => {
    axios
      .get(`${constants.url}/mediator/availability/${mediator_id}`,
        config
      )
      .then(res => dispatch(mediatorAvailabilitySuccess(res.data)))
      .catch(err => dispatch(mediatorAvailabilityFailure(err)));
  };
};

export const mediatorList = () => {
  const config = {
    headers: { 
      Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`}
  };

  return dispatch => {
    axios
      .get(`${constants.url}/mediators/list`,
        config
      )
      .then(res => dispatch(getListOfMediatorsSuccess(res.data)))
      .catch(err => dispatch(getListOfMediatorsFailure(err)));
  };
};

export const deleteAvailability = (id,datacallback) => {
  const config = {
    headers: { 
      Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`}
  };

  return dispatch => {
    axios
      .delete(`${constants.url}/mediator/availability/${id}/mediator/${localStorage.getItem("nlasdfALSERNSDln")}`,
        config
      )
      .then(res => datacallback(res))
      .catch(err => dispatch(getDeleteAvailabilityError(err)));
  };
};
export const mediatorEditApiCall = (data,callback) => {
  const config = {
    headers: { 
      Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`}
  };
  const bodyParameters = {
    comments: data.comments,
  };
  return dispatch => {
    axios
      .put(`${constants.url}/mediator/availability/${data.id}`,
      bodyParameters,
        config
      
      )
      .then(res => callback(res.data))
      .catch(err => dispatch(createMediatorAvailabilityFailure(err)));
  };
};

export const mediatorAvailabilityApiCall = (value,callback) => {
  const bodyParameters = {
    mediator_id: value.mediator_id,
    date:value.date,
    comments: value.comments,
  };
  const config = {
    headers: { 
      Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`}
  };

  return dispatch => {
    axios
      .post(`${constants.url}/mediator/availability?mediator_id&date&comments`,
        bodyParameters,
        config
      )
      .then(res => callback(res.data))
      .catch(err => dispatch(createMediatorAvailabilityFailure(err)));
  };
};

export const mediatorsForGivenMonth = (month,year) => {
 
  const config = {
    headers: { 
      Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`}
  };
  return dispatch => {
    axios
      .get(`${constants.url}/mediator/availability/month?month=${month}&year=${year}`,
        config
      )
      .then(res => dispatch(mediatorForGivenMonthSuccess(res.data)))
      .catch(err => dispatch(mediatorForGivenMonthFailure(err)));
  };
};

export function mediatorForGivenMonthSuccess(res) {
  return {
    type: actionTypes.MEDIATORS_FOR_MONTH_SUCCESS,
    payload:res
  }
}

export function mediatorForGivenMonthFailure(res) {
  return {
    type: actionTypes.MEDIATORS_FOR_MONTH_FAILURE,
    payload:res
  }
}

export function createMediatorAvailability() {
  return {
    type: actionTypes.CREATE_MEDIATOR_AVAILABILITY_SUCCESS,
  }
}

export function getListOfMediatorsSuccess(value) {
  return {
    type: actionTypes.GET_MEDIATOR_LIST,
    payload:value
  }
}

export function getListOfMediatorsFailure(value) {
  return {
    type: actionTypes.GET_MEDIATOR_LIST_FAILURE
  }
}
export function createMediatorAvailabilityFailure() {
  return {
    type: actionTypes.CREATE_MEDIATOR_AVAILABILITY_FAILURE,
  }
}

export function mediatorAvailabilitySuccess(value) {
  return {
    type: actionTypes.MEDIATOR_AVAILABILITY_SUCCESS,
    payload: value
  }
}
  
export function mediatorAvailabilityFailure() {
  return {
    type: actionTypes.MEDIATOR_AVAILABILITY_FAILURE,
  }
}

export function getMediationMA() {
  return {
    type: actionTypes.GET_MEDIATION_MA
  }
}
  
export function getMediationMASuccess(mma) {
  // console.log('getMediationMASuccess parent mma: '+JSON.stringify(mma));
  // for ( var i = 0 ; i < mma.data.length; i++ ){
  //   console.log('getMediationMASuccess mma row ['+(i+1)+']: '+JSON.stringify(mma.data[i]));
  // }
  return {
    type: actionTypes.GET_MEDIATION_MA_SUCCESS,
    payload: mma
  }
}
  
export function getMediationMAFailure(error) {
  return {
    type: actionTypes.GET_MEDIATION_MA_FAILURE,
    payload: error
  }
}

export const fetchMediationMA = (id) => {
  return dispatch => {
    dispatch(getMediationMA());
    axios
      .get(`${constants.url}/mediator/availability/mediation/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediationMASuccess(res.data)))
      .catch(err => dispatch(getMediationMAFailure(err)))
  }
}

export const fetchMediatorMediationsForDate = (mediator_id,date) => {
  return dispatch => {
    dispatch(getMediationMA());
    axios
      .get(`${constants.url}/mediator/availability/mediator/${mediator_id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediationMASuccess(res.data)))
      .catch(err => dispatch(getMediationMAFailure(err)))
  }
}

export function getDeleteAvailabilitySuccess(res) {
  return {
    type: actionTypes.GET_DELETE_AVAILABILITY_SUCCESS,
    payload: res
  }
}

export function getDeleteAvailabilityError(error) {
  return {
    type: actionTypes.GET_DELETE_AVAILABILITY_FAILURE,
    payload: error
  }
}
