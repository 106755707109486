import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { checkAuthentication, getRole } from 'redux/selectors';

const PrivateRoute = ({ component: Component, user, auth, role, ...rest }) => {
  let query = useQuery();
  return(
    <Route 
      {...rest}
      render = { props => 
      {
        if(!auth) {
          if(query.get("role") === "admin") {
            return props.history.push(`/admin/login?redirect=${props.location.pathname}`);
            // return <Redirect to={{pathname: `/admin/login?redirect=${props.location.pathname}`, state: { from: props.location }}} />
          }
          return <Redirect to={{pathname: "/", state: { from: props.location }}} />
        } else if(role !== user) {
          return <Redirect to={{ pathname: `/${role}/panel` }} />
        } else {
          return <Component {...props} /> 
        }
      }
      }
    />
  )
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapStateToProps = state => {
  const auth = checkAuthentication();
  const role = getRole();
  return {auth, role}
} 

export default connect(mapStateToProps)(PrivateRoute);
