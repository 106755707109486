import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import CheckboxGroup from 'components/form/CheckboxGroup';
import { states } from 'util/dataUtil';
import { Field, reduxForm } from "redux-form";
import { Card, Container, Row, Form, Col, Button } from 'react-bootstrap';
import { addMediator, modifyMediator } from 'redux/actions/mediator';
import { fetchCertifications } from 'redux/actions/certification';
import { fetchExpertises } from 'redux/actions/expertise';
import { fetchMediator, uploadMediatorImage, downloadMediatorImage } from 'redux/actions/mediator';
import { fetchConfirmationRate, fetchRecentMediations } from 'redux/actions/mediation';
import { required, email, mobileNumber, number, passwordsMatch } from 'util/validationUtil';
import { reduxFormInput, reduxFormTextarea, reduxFormSelect } from 'components/form/ReduxForm';
import { isCertificationLoading, isExpertiseLoading, isMediatorLoading } from 'redux/selectors';
import Loader from "components/ui/Loader";

const CreateNewMediator = (props) => {
  let imageUpload = React.createRef();
  const [uploadedFile, setUploadedFile] = useState();
  const [img, setImg] = useState();
  const [newImage, setNewImage] = useState(false);

  const { 
    handleSubmit, 
    isLoading, 
    certifications, 
    expertises, 
    imageUploaded, 
    mediatorCreatedOrUpdated,
    createdMediator
  } = props;

  if (mediatorCreatedOrUpdated && newImage) {
    const formData = new FormData();
    const imagedata = img;
    formData.append('PROFILE_PHOTO', imagedata);
    props.uploadMediatorImage(createdMediator.id, formData);
  } else if (imageUploaded || mediatorCreatedOrUpdated) {
    props.history.push('/admin/all/mediators');
  }

  useEffect(() => {
    props.fetchCertifications();
    props.fetchExpertises();
  }, [])

  const form_certifications = [];
  certifications.forEach( certificate => 
    form_certifications.push({ label: certificate.certification_type, value: certificate.certification_type })
  );

  const form_expertises = [];
  expertises.forEach( expertise => 
    form_expertises.push({ label: expertise.name, value: expertise.name })
  );

  const submitFn = useCallback( data => {
    data.state = data.state.value;
    if(data.certifications) {
      const temp_certificates = [];
      data.certifications.forEach(certificate => {
        const temp = certifications.find(cert =>  cert.certification_type === certificate );
        if (temp) temp_certificates.push(temp.id);
      });
      data.certification_ids = temp_certificates.join();
    }
    if(data.expertizes) {
      const temp_expertises = [];
      data.expertizes.forEach(expertise => {
        const temp = expertises.find(expert => expert.name === expertise);
        if (temp) temp_expertises.push(temp.id);
      });
      data.expertise_ids = temp_expertises.join();
    }
    props.addMediator(data);

  }, [certifications, expertises, img]);

  const handleFileChange = useCallback (e => {
    setUploadedFile(URL.createObjectURL(e.target.files[0]));
    setNewImage(true);
  }, []);

  useEffect(() => {
    setImg(imageUpload.current.files[0]);
  }, [uploadedFile])

  return(
    <>
      { isLoading && <Loader /> }
      <main className="fc-content">
        <Spacer size={30} />
        <Container fluid>
          <Row>
            <Col xs="12">
              <h3>New Mediator</h3>
              <Spacer size={15} />
            </Col>
            <Form id="createUI" className="w-100" onSubmit={handleSubmit(submitFn)} autoComplete="off" >
              <Row>
                <Col xs="12" sm="6">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                      Add Image:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <div className="upload-wrapper" style={uploadedFile !== null ? {"backgroundImage": `url(${uploadedFile})`} : {"": ""}}>
                        <Button variation="primary-img"></Button>
                        <input ref={imageUpload} type="file" name="IMAGE" accept=".png, .jpg, .jpeg" onChange={handleFileChange}/>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        First Name:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="first_name" 
                        type="text" 
                        placeholder="First name..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Last Name:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="last_name" 
                        type="text" 
                        placeholder="Last name..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Email Address:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, email]}
                        name="email" 
                        type="email" 
                        placeholder="Email address..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Phone Number:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, mobileNumber]}
                        name="phone_number" 
                        type="text" 
                        placeholder="Phone number..."
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Notes:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormTextarea}
                        name="notes" 
                        rows="3" 
                        placeholder="Notes..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Mailing Address:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="address_line" 
                        type="text" 
                        placeholder="Mailing address..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <Row>
                        <Col xs="5" className="pl-0 pr-1">
                          <Field 
                            className="form-control" 
                            component={reduxFormInput}
                            validate={[required]}
                            name="city" 
                            type="text" 
                            placeholder="City..." 
                          />
                        </Col>
                        <Col xs="4" className="pl-0 pr-0">
                          <Field
                            component={reduxFormSelect}
                            validate={[required]}
                            options={states}
                            name="state"
                          />
                        </Col>
                        <Col xs="3" className="pl-1 pr-0">
                          <Field 
                            className="form-control" 
                            component={reduxFormInput}
                            validate={[required, number]}
                            name="zip" 
                            type="number" 
                            placeholder="Zip" 
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs="12" sm="6">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Password:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="password" 
                        type="password" 
                        autocomplete="new-password"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Confirm Password:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, passwordsMatch]}
                        name="confirm_password" 
                        type="password" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <Card>
                        <Card.Header>
                          <Card.Title>Certifications</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <CheckboxGroup name="certifications" options={form_certifications} />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <Card>
                        <Card.Header>
                          <Card.Title>Areas of Expertise</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <CheckboxGroup name="expertizes" options={form_expertises} />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={20} />
                  <Button type="submit" variant="primary-alt" size="lg">
                      Save
                  </Button>
                  <Spacer size={20} />
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </main>
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = isMediatorLoading(state) 
    || isCertificationLoading(state) 
    || isExpertiseLoading(state) 
    || state.mediationReducer.isLoading.includes('GET_CONFIRMATION_RATE')
    || state.mediationReducer.isLoading.includes('GET_RECENT_MEDIATIONS');
  const certifications = state.certificationReducer.certifications;
  const expertises = state.expertiseReducer.expertises;
  const imageUploaded = state.mediatorReducer.callSuccessful.includes('UPLOAD_IMAGE');
  const mediatorCreatedOrUpdated = state.mediatorReducer.callSuccessful.includes('CREATE_MEDIATOR')
    || state.mediatorReducer.callSuccessful.includes('UPDATE_MEDIATOR');
  const createdMediator = state.mediatorReducer.mediator;
  return { isLoading, certifications, expertises, imageUploaded, mediatorCreatedOrUpdated, createdMediator }
}

export default connect(
  mapStateToProps, 
  {
    fetchCertifications,
    fetchExpertises,
    fetchMediator,
    fetchConfirmationRate,
    fetchRecentMediations,
    addMediator,
    modifyMediator,
    uploadMediatorImage,
    downloadMediatorImage
  })(
  reduxForm({
    form: "createMediator",
    enableReinitialize: true,
  })(CreateNewMediator)
);
