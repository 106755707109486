import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { fetchReport } from 'redux/actions/mediation';
import { fetchMediatorActivityReport } from 'redux/actions/report';
import moment from 'moment';
import Loader from "components/ui/Loader";
import DatePicker from "react-datepicker";

function MediatorActivityReport(props){

  const [startDate, setStartDate] = useState(new Date("01/01/"+(new Date()).getFullYear()));
  const [endDate, setEndDate] = useState(new Date());
  const { isLoading, report } = props;

  useEffect(() => {
    props.fetchReport(`${new Date().getFullYear()}-01-01`, moment(new Date()).format('YYYY-MM-DD'));
  }, []);

  const getReport = useCallback(() => {
    props.fetchMediatorActivityReport(moment(startDate).format('YYYY/MM/DD'), moment(endDate).format('YYYY/MM/DD'));
  }, []);

  const handleSubmit = useCallback( () => {
    props.fetchReport(moment(startDate).format('YYYY/MM/DD'), moment(endDate).format('YYYY/MM/DD'));
  }, [startDate, endDate]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Worked',
            accessor: 'worked'
          },
          {
            Header: 'Confirmed',
            accessor: 'confirmed'
          },
          {
            Header: 'Declined',
            accessor: 'declined'
          },
          {
            Header: 'Confirmation Ratio',
            accessor: 'confirmation_ratio'
          }
        ],
      },
    ],
    [report]
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Mediator Activity Report</h3>
          <Spacer size={5} />
          <Row>
            <Col sm="2" xs="12">
              <p>From Date:</p>
            </Col>
            <Col sm="3" xs="12">
              <DatePicker className="date-picker" dateFormat="MM/dd/yyyy" selected={startDate} onChange={date => setStartDate(date)} />
            </Col>
            <Col sm="2" xs="12">
              <p>To Date:</p>
            </Col>
            <Col sm="3" xs="12">
              <DatePicker className="date-picker" dateFormat="MM/dd/yyyy" selected={endDate} onChange={date => setEndDate(date)} />
            </Col>
            <Col sm="2" xs="12">
              <Button onClick={handleSubmit} disabled={!(startDate && endDate)} > 
                  Update
              </Button>
            </Col>
          </Row>
          <Spacer size={5} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={report}
              tableHeader={false} />
          </div>
          <Spacer size={10} />
          <Button className="btn-second-alt" variant="secondary-alt" onClick={getReport} >
            Export
          </Button>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = state.mediationReducer.isLoading.includes('GET_MEDIATION_REPORT');
  const report = state.mediationReducer.report;
  return { isLoading, report }
}

export default connect(
  mapStateToProps,
  {
    fetchReport,
    fetchMediatorActivityReport
  }
)(MediatorActivityReport)
