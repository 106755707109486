import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Row, Col, Button, Image, Modal, Form } from 'react-bootstrap';
import { Field, reduxForm } from "redux-form";
import { addScheduler, modifyScheduler, fetchSchedulers, removeScheduler, updateSchedulerPassword } from 'redux/actions/scheduler';
import Loader from "components/ui/Loader";
import ConfirmationModal from 'components/ui/ConfirmationModal';
import { isSchedulerLoading } from 'redux/selectors';
import DeleteImage from 'images/DeleteWhite.png';
import EditImage from 'images/EditWhite.png';
import checkImage from 'images/check.png';
import uncheckImage from 'images/uncheck.png';
import { reduxFormInput } from 'components/form/ReduxForm';
import { required, email, mobileNumber, passwordsMatch } from 'util/validationUtil';
import EditScheduler from 'containers/modal/EditScheduler';

function ManageSchedulers(props){
  const [modalShow, setModalShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [schedulerId, setSchedulerId] = useState(0);

  const { handleSubmit, isLoading, schedulers, scheduler, isDeleted } = props;

  const initialValues = schedulers.find(scheduler => scheduler.id === schedulerId);
  if(initialValues) initialValues.is_active = (initialValues.is_active === '1' || initialValues.is_active === 1) ? 1 : 0;

  useEffect(() => {
    props.fetchSchedulers();
  }, [scheduler, isDeleted])

  const editProfile = useCallback(id => {
    setSchedulerId(id);
    setEditShow(true);
  }, [initialValues]);

  const canDelete = useCallback( id => {
    setModalShow(true);
    setSchedulerId(id);
  }, []);

  const confirmDeletion = useCallback( id => {
    setModalShow(false);
    props.removeScheduler(id);
  }, []);

  const createScheduler = useCallback( () => {
    setProfileShow(true);
  }, []);

  const submitFn = useCallback( data => {
    props.addScheduler(data);
    setProfileShow(false);
  }, []);

  const clearFields = useCallback( () => {
    setProfileShow(false);
    props.dispatch(reset('createScheduler'));
  }, []);

  const editSubmit = useCallback( data => {
    props.modifyScheduler(data.id, data);
    setEditShow(false);
  }, []);

  const updatePassword = useCallback( id => {
    setSchedulerId(id);
    setPasswordShow(true);
  }, []);

  const submitPasswordFn = useCallback( data => {
    props.updateSchedulerPassword(schedulerId, data);
    setPasswordShow(false);
  }, [schedulerId]);
  
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: '',
            accessor: 'id',
            Cell: ({ cell: { value } }) => <EditCell id={value} delete={canDelete} edit={editProfile} update={updatePassword} />
          },
          {
            Header: 'Last Name',
            accessor: 'last_name'
          },
          {
            Header: 'First Name',
            accessor: 'first_name'
          },
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'Phone',
            accessor: 'phone_number',
            Cell: ({ cell: { value }  }) => {
              if(value)
                return value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
              return ''
            }
          },
          {
            Header: 'Active',
            accessor: 'is_active',
            Cell: ({ cell: { value } }) => <CheckStatus status={value}/>
          }
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Manage Schedulers</h3>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={schedulers}
              tableHeader={false} />
          </div>
          <Spacer size={3} />
          <Button className="btn-second-alt" variant="secondary-alt"onClick={createScheduler} >
            Add New Scheduler
          </Button>
          <AddEditScheduler 
            show={profileShow}
            onHide={clearFields}
            onSubmit={handleSubmit(submitFn)}
          />
          <EditScheduler 
            show={editShow}
            onHide={() => setEditShow(false)}
            update={editSubmit}
            initialValues={initialValues}
          />
          <UpdatePassword 
            show={passwordShow}
            onHide={() => setPasswordShow(false)}
            onSubmit={handleSubmit(submitPasswordFn)}
            data={initialValues}
          />
          <ConfirmationModal
            show={modalShow}
            id={schedulerId}
            onHide={() => setModalShow(false)}
            onConfirm={confirmDeletion}
          />
        </Container>
      </div>
    </>
  )
}

const EditCell = (props) => {
  return(
    <>
      <Image className="editdelete" src={EditImage} onClick={() => props.edit(props.id)}/>
      <Image className="editdelete" src={DeleteImage} onClick={() => props.delete(props.id)} />
      <Button className="link" variant="link" size="sm" onClick={() => props.update(props.id)} >Reset Password</Button>
    </>
  )
}

const CheckStatus = (props) => {
  return(
    <>
      { props.status === 1 || props.status === '1' ?
        <Image className="checkuncheck" src={checkImage}/> : 
        <Image className="checkuncheck" src={uncheckImage}/> }
    </>
  )
}

const AddEditScheduler = (props) => {
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Add New Scheduler</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container fluid>
            <Spacer size={3} />
            <Form id="createSchdulerUI" className="w-100" >
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        First Name:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="first_name" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Last Name:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="last_name" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Email Address:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, email]}
                        name="email" 
                        type="email" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Phone Number:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, mobileNumber]}
                        name="phone_number" 
                        type="text" 
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Confirm Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, passwordsMatch]}
                        name="confirm_password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={2} />
                  <Button type="submit" className="primary" variant="primary-alt" size="sm">
                    Save
                  </Button>
                  <Spacer size={2} />
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const UpdatePassword = (props) => {
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Reset Password</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container fluid>
            <Spacer size={3} />
            <h5>{props.data && props.data.last_name}, {props.data && props.data.first_name}</h5>
            <Spacer size={3} />
            <Form id="updatePassUI" className="w-100" >
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        New Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                        Confirm Password:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, passwordsMatch]}
                        name="confirm_password" 
                        type="password" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={2} />
                  <Button type="submit" className="primary" variant="primary-alt" size="sm">
                    Save
                  </Button>
                  <Spacer size={2} />
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => {
  const isLoading = isSchedulerLoading(state);
  const schedulers = state.schedulerReducer.schedulers;
  const scheduler = state.schedulerReducer.scheduler;
  const isDeleted = state.schedulerReducer.callSuccessful.includes('DELETE_SCHEDULER');
  return { isLoading, schedulers, scheduler, isDeleted }
}

const connectedReduxForm = reduxForm({
  form: 'createScheduler',
  enableReinitialize: true,
})(ManageSchedulers);

export default connect(
  mapStateToProps,
  {
    fetchSchedulers,
    removeScheduler,
    addScheduler, 
    modifyScheduler,
    updateSchedulerPassword
  }
)(connectedReduxForm)