import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

export const getIsLoggingIn = store => store.isLoggingIn;

export const checkAuthentication = () => {
  const isAuthenticated = getValueFromLocal(constants.JWT_ACCESS_KEY);
  return isAuthenticated ? true : false; 
}

export const getRole = () => {
  const role = getValueFromLocal(constants.ROLE);
  return role ? role : ""; 
}

export const checkPasswordSet = () => {
  const isPasswordSet = getValueFromLocal(constants.IS_PASSWORD_SET);
  return isPasswordSet == 1 ? true : false;
}

export const getCurrentPages = (store, tableCount) => {
  const currentPageIndex = store.paginate.currentPageIndex;
  const paginateIndex = (currentPageIndex - 1);
  const pageCount = Math.floor(tableCount / constants.PAGE_SIZE);
  const paginateSize = constants.PAGINATE_SIZE;
  const currentPages = [];
  if (currentPageIndex === 0) {
    [...new Array(paginateSize)].forEach((key, index)=>{
      currentPages.push(2 + index);
    })
  } else if (paginateIndex + paginateSize < pageCount) {
    [...new Array(paginateSize)].forEach((key, index)=>{
      currentPages.push( Math.floor(paginateIndex / paginateSize) * paginateSize + index + 2);
    })
  } else {
    [...new Array(paginateSize)].forEach((key, index)=>{
      currentPages.push(pageCount-3+index);
    })
  }
  return currentPages;
}

export const isMediatorLoading = state => {
  return state.mediatorReducer.isLoading.includes('CREATE_MEDIATOR')
  || state.mediatorReducer.isLoading.includes('GET_MEDIATOR')
  || state.mediatorReducer.isLoading.includes('GET_MEDIATOR_IMAGE')
  || state.mediatorReducer.isLoading.includes('UPLOAD_IMAGE')
  || state.mediatorReducer.isLoading.includes('UPDATE_MEDIATOR')
  || state.mediatorReducer.isLoading.includes('UPDATE_MEDIATOR_PASSWORD')
  || state.mediatorReducer.isLoading.includes('GET_MEDIATOR_IMAGE');
}

export const isAdministratorLoading = state => {
  return state.administratorReducer.isCalling.includes('CREATE_ADMINISTRATOR')
  || state.administratorReducer.isCalling.includes('GET_ADMINISTRATORS')
  || state.administratorReducer.isCalling.includes('UPDATE_ADMINISTRATOR')
  || state.administratorReducer.isCalling.includes('UPDATE_ADMIN_PASSWORD')
  || state.administratorReducer.isCalling.includes('DELETE_ADMINISTRATOR');
}

export const isSchedulerLoading = state => {
  return state.schedulerReducer.isCalling.includes('CREATE_SCHEDULER')
  || state.schedulerReducer.isCalling.includes('GET_SCHEDULERS')
  || state.schedulerReducer.isCalling.includes('UPDATE_SCHEDULER')
  || state.schedulerReducer.isCalling.includes('UPDATE_SCHEDULER_PASSWORD')
  || state.schedulerReducer.isCalling.includes('DELETE_SCHEDULER');
}

export const isCertificationLoading = state => {
  return state.certificationReducer.isCalling.includes('CREATE_CERTIFICATION')
  || state.certificationReducer.isCalling.includes('GET_CERTIFICATIONS')
  || state.certificationReducer.isCalling.includes('UPDATE_CERTIFICATION')
  || state.certificationReducer.isCalling.includes('DELETE_CERTIFICATION');
}

export const isExpertiseLoading = state => {
  return state.expertiseReducer.isCalling.includes('CREATE_EXPERTISE')
  || state.expertiseReducer.isCalling.includes('GET_EXPERTISES')
  || state.expertiseReducer.isCalling.includes('UPDATE_EXPERTISE')
  || state.expertiseReducer.isCalling.includes('DELETE_EXPERTISE');
}
