import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Spacer from 'components/layout/Spacer';

class AdminSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.sidebar = React.createRef();
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
      >
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <main className="fc-content">
            <Spacer size={30} />
            <Container fluid>
              <Row>
                <Col>
                  <aside>
                    <ul>
                      <li className="back"><Link to="/admin/panel">Back to Portal</Link></li>
                      <li><Spacer size={5} /></li>
                      <li>Scheduling</li>
                      <li><Link to="/admin/manage/mediations">Manage Mediations</Link></li>
                      <li><Link to="/admin/schedule/mediations">Schedule Mediations</Link></li>
                      <li><Link to="/admin/mediator/availability">View Mediator Availability</Link></li>
                      <li><Link to="/admin/mediation/confirmations">View Confirmations</Link></li>
                      <li><Link to="/admin/mediation/declined">View Declined</Link></li>
                      <li><Spacer size={5} /></li>
                      <li>Program Management</li>
                      <li><Link to="/admin/manage/mediators">Manage Mediators</Link></li>
                      <li><Link to="/admin/all/mediators">View All Mediators</Link></li>
                      <li><Link to="/admin/mediator">Create New Mediator</Link></li>
                      <li><Link to="/admin/manage/administrators">Manage Administrators</Link></li>
                      <li><Link to="/admin/manage/schedulers">Manage Schedulers</Link></li>
                      <li><Link to="/admin/manage/expertises">Manage Expertises</Link></li>
                      <li><Link to="/admin/manage/certifications">Manage Certifications</Link></li>
                      <li><Spacer size={5} /></li>
                      <li><Link to="/admin/activityreport">Mediator Activity Report</Link></li>
                    </ul>
                    <Spacer size={30} />
                  </aside>
                </Col>
              </Row>
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default AdminSidebar;
