import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Spacer from 'components/layout/Spacer';
import { Redirect } from 'react-router-dom';
import { Container, Button, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import Calendar from 'containers/mediator/Calendar';
import Loader from "components/ui/Loader";
import Select from 'react-select';
import {mediatorAvailabilityApiCall,mediatorEditApiCall, mediatorAvailability, mediatorsForGivenMonth, mediatorList, deleteAvailability} from 'redux/actions/mediatorAvailability';

class ViewMediatorAvailability extends React.Component {
  constructor(){
    super();
    this.state={
      monthSelected:moment().format('MMM'),
      currentYear:moment().format('YYYY'),
      presentYear:moment().format('YYYY'),
      arrowClicked:false,
      currentMonth:'',
      radioMonth:true,
      radioMediator: false,
      showLoader:false,
      selectedMediator:'',
      selectedMediatorData:[],
      mediatorFromdropdown:1,
      selectedOption:null,
      mediatorName:[],
      dropdownSelect:false,
      showCalled:false,
      saveClicked:false,
      mediatorAvailabilityView:false,
      day:'',
      selectedDaytMediators:'',
      backtoCalendar:true,
      mediatorNameClick:false,
      redirectId:'',
      dropdownSelected:'',
      showMessage:false,
      idExtract:''
    }
    this.ref = React.createRef();
  }
  componentDidMount(){
    /**api call for mediator by month */
    this.loadComponent()
  }

  componentDidUpdate(prevProps){
   
    if(prevProps.mediatorsByMonth.data!=this.props.mediatorsByMonth.data){
      this.setState({
        showCalled:true,
        showLoader:false,
      })
    }
    if(this.state.dropdownSelect==true || this.state.saveClicked==true){
      if(prevProps.mediatorAvailabilityResponse.data!=this.props.mediatorAvailabilityResponse.data){
        this.setState({
          showCalled:true,
          showLoader:false,
        })
      }
    }
  }

  loadComponent(){
    let idExtract
    let mediatorFromdropdown
    let values
    if(this.props.location.search!=""){
      values = queryString.parse(this.props.location.search)
      this.props.mediatorAvailability(values.id)
      idExtract=values.id
      mediatorFromdropdown=parseInt(values.id)
      this.setState({
        radioMonth:false,
        radioMediator: true, 
      })
    }
    this.setState({
      showLoader:true,
      idExtract,
      mediatorFromdropdown
    })
    this.props.mediatorsForGivenMonth(moment().month(this.state.monthSelected).format("MM"),this.state.currentYear)
    this.props.mediatorList();
  }
  callback=(value)=>{
    if(value){
      this.loadMediatorAvailability(this.state.mediatorFromdropdown)
    }
  }

  loadMediatorAvailability = (mediatorFromdropdown) =>{
    let mediator_id
    mediator_id=mediatorFromdropdown
    this.props.mediatorAvailability(mediator_id)
  }
  /**saving mediator availability */
  newMediatorAvailability = (date,comments,editApi) => {
    let mediatorData
    let mediatorPlan={
      mediator_id:this.state.mediatorFromdropdown,
      date:date,
      comments:comments}
    this.setState({
      showLoader:true,
      saveClicked:true
    })
    if(editApi){
      mediatorData={
        id:editApi,
        comments:comments
      }
      this.props.mediatorEditApiCall(mediatorData,this.callback)
    }
    else{
      // console.log('adminscheduler.newMediatorAvailability called');
      this.props.mediatorAvailabilityApiCall(mediatorPlan,this.callback)
    }
  }

   monthChangedviaArrowClick = (value) =>{
     this.props.mediatorsForGivenMonth(moment(value).format("MM"),moment(value).format('YYYY'))
     this.setState({
       arrowClicked:true,
       showLoader:true,
       currentMonth:value,
       showCalled:true,
       monthSelected:moment(value).format('MMM')
     })
   }

     /** Selecting dropdown*/
     handleMediatorSelection = (event) =>{
       this.setState({
         mediatorFromdropdown:event.value,
         dropdownSelected:event,
         showLoader:true,
         dropdownSelect:true,
         showCalled:true,
         showMessage:false
       })
       this.props.mediatorAvailability(event.value)
     }
     
     handleRadioChangeMonth = () =>{
       if( this.state.radioMediator == true){
         this.setState({
           radioMonth:true,
           radioMediator:false,
           showLoader:true,
           mediatorAvailabilityView:false
           // showCalled:false
         })
       }
       else{
         this.setState({
           radioMonth:true,
           showLoader:true,
           mediatorAvailabilityView:false
           // showCalled:false
         })
       }
       this.props.mediatorsForGivenMonth(moment().month(this.state.monthSelected).format("MM"),
         this.state.currentYear)

     }

     handleRadioChangeMediator = () =>{
       //getting the list of mediators
       let mediatorName
       if(this.state.dropdownSelected==''){
         this.setState({
           showMessage:true
         })
       }
       if(this.state.radioMonth==true){
         if(this.props.mediatorList1.data!=undefined){
           mediatorName= this.props.mediatorList1.data.map(value=>{
             return {value:value.id,
               label:value.first_name+" "+value.last_name}
           })
         }
        
         mediatorName=Array.from(new Set(mediatorName))
         this.setState({
           radioMediator:true,
           radioMonth:false,
           mediatorName,
           mediatorFromdropdown:1
         })
       }
       else{
         this.setState({
           radioMediator:true
         })
       }
     }

     deleteAvailability=(id)=>{
       this.setState({
         showLoader:true
       })
       this.props.deleteAvailability(id, this.deleteCallback)
     }

    deleteCallback =(value) =>{
      if(value.data == 'Deleted Successfully'){
        this.setState({
          showLoader:false
        })
        this.loadMediatorAvailability(this.state.mediatorFromdropdown)
      }
    }
    linkToMediatorEdit = (id) => {
      this.setState({
        redirectId:id,
        mediatorNameClick:true
      })
    }

    byMediatorClick = (day) =>{
      let formatDay=moment(day).format('YYYY-MM-DD')
      let selectedDaytMediators=this.props.mediatorsByMonth.data.filter(item=>item.date==formatDay)
      this.setState({
        mediatorAvailabilityView:selectedDaytMediators.length>0?true:false,
        day:moment(day).format("MMMM DD,YYYY"),
        selectedDaytMediators
      })
    }

    render() {
      let selectedMonthItem;
      let mediatorNameListDropdown
      let selectedDropdown;
      let loadershow= this.state.showLoader
      if(this.state.radioMonth==true ){
        if(this.props.mediatorsByMonth.data!=undefined){
          selectedMonthItem=  this.props.mediatorsByMonth.data
        }}
      const customStyles = {
        menu: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted pink',
          color: "black",
      
        }),
      }
      if(this.state.radioMediator==true){
        if(this.props.mediatorAvailabilityResponse.data!=undefined){
          if(this.state.dropdownSelected==false && this.props.location.search==""){
            selectedMonthItem=undefined
          }
          else{
            selectedMonthItem=this.props.mediatorAvailabilityResponse.data.map(item=>{
              if(moment(item.date).format("MMM")==this.state.monthSelected){
                return item
              }
            })
          }
        }
      }
  
      let yearArray=[]
      for(let i=1970;i<=this.state.presentYear;i++){
        yearArray.push(i);
      }
      if(this.state.mediatorNameClick==true){
        return <Redirect to={`/admin/mediator/edit?id=${this.state.redirectId}`}/>
      }

      if( selectedMonthItem!=undefined && this.state.showCalled==false){
        loadershow=false
      }
      if(this.props.mediatorList1.data!=undefined && this.props.location.search!="")
      {  this.props.mediatorList1.data.map(item=>{
        if(item.id==this.state.idExtract){
          selectedDropdown =  {value:item.id,
            label:item.first_name+" "+item.last_name}
        }
      })
      }
      if(this.props.mediatorList1.data!=undefined && this.props.location.search!="")
      {   mediatorNameListDropdown= this.props.mediatorList1.data.map(value=>{
        return {value:value.id,
          label:value.first_name+" "+value.last_name}
      })
      mediatorNameListDropdown=Array.from(new Set(mediatorNameListDropdown))
      }
      if(this.state.dropdownSelect==true){
        selectedDropdown=undefined
      }
      return(
        <>
          <div className="fc-content">
            <Spacer size={30} />
            <Container fluid>
              <h2>View Mediator Availability</h2>
            </Container>
            <div style={{marginLeft: "16px"}}>
              <div style={{float:"left",paddingRight: "32px"}}>
                <input type="radio" 
                  checked={this.state.radioMonth == true? true: false}
                  onChange={this.handleRadioChangeMonth} /> By Month 
              </div>
              <div>
                <input type="radio" checked={this.state.radioMediator == true? true: false}
                  onChange={this.handleRadioChangeMediator}/> By Mediator 
              </div>
              { loadershow==true ?<Loader /> :""}
              {/**mediator dropdown */}
              {this.state.radioMediator==true?
                <div style={{paddingRight: "200px", marginTop: "20px"}} className="" >
                  <Select
                    value={selectedDropdown!=undefined?selectedDropdown:this.state.dropdownSelected}
                    onChange={(event)=>this.handleMediatorSelection(event)}
                    options={mediatorNameListDropdown!=undefined?mediatorNameListDropdown:this.state.mediatorName}
                    className="dropdownFix"
                    styles={customStyles}
                  />
                </div>
                :""}
            </div>
            <br/>
            {this.state.mediatorAvailabilityView==true && this.state.radioMediator==false?
              <div style={{marginLeft: "16px"}}>
                Mediators available on {this.state.day}
                <Row>
                  <Col xs="12" sm="9">
                    <Card style={{ marginTop: "16px"}}>
                      <Card.Body>
                        <Card.Text>
                          {
                            this.state.selectedDaytMediators.map(item=>{
                              return(
                                <>
                                  <Button variant="linked" onClick={()=>this.linkToMediatorEdit(item.mediator_id)}>{item.name}</Button>
                                  {item.comments ? item.comments : ''}<br></br>
                                </>
                              ) 
                            })
                          }
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <div style={{marginTop:"16px"}}>
                  <Button className="btn-save" variant="primary-sm" size="sm" onClick={()=>{
                    this.setState({
                      mediatorAvailabilityView:false
                    })
                  }}  >
                    Back to Calendar
                  </Button>
                </div>
              </div>:
              <Calendar 
                isActive={1}
                currentYear={this.state.currentYear}
                newMediatorAvailability={this.newMediatorAvailability} 
                selectedMonthItem={selectedMonthItem}
                monthSelected={this.state.monthSelected}
                byMediator={this.state.radioMediator}
                currentMonth={this.state.currentMonth}
                monthChangedviaArrowClick={this.monthChangedviaArrowClick}
                isAdmin={true}
                showLoader={loadershow}
                deleteAvailability={this.deleteAvailability}
                byMediatorClick={this.byMediatorClick}
              />}
          </div>
        </>
      )
    }
}
function mapStateToProps(state) {
  return {
    mediatorAvailabilityResponse: state.mediatorAvailabilityReducer.mediatorAvailabilityResponse,
    mediatorsByMonth:state.mediatorAvailabilityReducer.mediatorsByMonth ,
    mediatorList1:state.mediatorAvailabilityReducer.mediatorList!=undefined?state.mediatorAvailabilityReducer.mediatorList:"",
    loading:state.mediatorAvailabilityReducer.loading
  }
}
const mapDispatchToProps = {
  mediatorAvailabilityApiCall,
  mediatorAvailability,
  mediatorsForGivenMonth,
  mediatorList,
  deleteAvailability,
  mediatorEditApiCall
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMediatorAvailability)