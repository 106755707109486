import { actionTypes } from '../actions/actionTypes';
import { saveValueInLocal } from 'util/storageUtil';
import constants from 'config/constants';

const initialState = {
  isLoading: [],
  loadingFailed: [],
  callSuccessful: [],
  existingMediator: '',
  mediator: '',
  image: '',
  mediators: [],
  searchMediators: [],
  error: ''
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CREATE_MEDIATOR: 
    return {
      ...state,
      existingMediator: '',
      isLoading: [...state.isLoading, "CREATE_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATOR'})
    }
  case actionTypes.CREATE_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "CREATE_MEDIATOR"],
      mediator: action.payload.data
    }
  case actionTypes.CREATE_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'CREATE_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "CREATE_MEDIATOR"],
      error: action.payload
    }
  case actionTypes.GET_MEDIATOR: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR'})
    }
  case actionTypes.GET_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "GET_MEDIATOR"],
      existingMediator: action.payload.data
    }
  case actionTypes.GET_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "GET_MEDIATOR"],
      error: action.payload
    }
  case actionTypes.GET_MEDIATORS: 
    return {
      ...state,
      mediator: '',
      existingMediator: '',
      image: '',
      isLoading: [...state.isLoading, "GET_MEDIATORS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATORS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATORS'})
    }
  case actionTypes.GET_MEDIATORS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATORS'}),
      callSuccessful: [...state.isLoading, "GET_MEDIATORS"],
      mediators: action.payload.data
    }
  case actionTypes.GET_MEDIATORS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATORS'}),
      loadingFailed: [...state.isLoading, "GET_MEDIATORS"],
      error: action.payload
    }
  case actionTypes.SEARCH_MEDIATOR: 
    return {
      ...state,
      isLoading: [...state.isLoading, "SEARCH_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'SEARCH_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'SEARCH_MEDIATOR'})
    }
  case actionTypes.SEARCH_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'SEARCH_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "SEARCH_MEDIATOR"],
      searchMediators: action.payload.data
    }
  case actionTypes.SEARCH_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'SEARCH_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "SEARCH_MEDIATOR"],
      error: action.payload
    }
  case actionTypes.GET_MEDIATOR_IMAGE: 
    return {
      ...state,
      isLoading: [...state.isLoading, "GET_MEDIATOR_IMAGE"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_IMAGE'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_IMAGE'})
    }
  case actionTypes.GET_MEDIATOR_IMAGE_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_IMAGE'}),
      callSuccessful: [...state.isLoading, "GET_MEDIATOR_IMAGE"],
      image: action.payload.data
    }
  case actionTypes.GET_MEDIATOR_IMAGE_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'GET_MEDIATOR_IMAGE'}),
      loadingFailed: [...state.isLoading, "GET_MEDIATOR_IMAGE"],
      error: action.payload
    }
  case actionTypes.UPLOAD_IMAGE: 
    return {
      ...state,
      isLoading: [...state.isLoading, "UPLOAD_IMAGE"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'UPLOAD_IMAGE'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'UPLOAD_IMAGE'})
    }
  case actionTypes.UPLOAD_IMAGE_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPLOAD_IMAGE'}),
      callSuccessful: [...state.isLoading, "UPLOAD_IMAGE"],
      mediator: action.payload.data
    }
  case actionTypes.UPLOAD_IMAGE_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPLOAD_IMAGE'}),
      loadingFailed: [...state.isLoading, "UPLOAD_IMAGE"],
      error: action.payload
    }
  case actionTypes.UPDATE_MEDIATOR:
    return {
      ...state,
      isLoading: [...state.isLoading, "UPDATE_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR'})
    }
  case actionTypes.UPDATE_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "UPDATE_MEDIATOR"],
      mediator: action.payload.data
    }
  case actionTypes.UPDATE_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "UPDATE_MEDIATOR"],
      error: action.payload
    }
  case actionTypes.UPDATE_MEDIATOR_PASSWORD:
    return {
      ...state,
      isLoading: [...state.isLoading, "UPDATE_MEDIATOR_PASSWORD"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR_PASSWORD'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR_PASSWORD'})
    }
  case actionTypes.UPDATE_MEDIATOR_PASSWORD_SUCCESS:
    saveValueInLocal(constants.IS_PASSWORD_SET, action.payload.data.is_password_set);
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR_PASSWORD'}),
      callSuccessful: [...state.isLoading, "UPDATE_MEDIATOR_PASSWORD"]
    }
  case actionTypes.UPDATE_MEDIATOR_PASSWORD_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR_PASSWORD'}),
      loadingFailed: [...state.isLoading, "UPDATE_MEDIATOR_PASSWORD"],
      error: action.payload
    }
  case actionTypes.ALLOW_MEDIATOR_PASSWORD_CHANGE:
    return {
      ...state,
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATOR_PASSWORD'})
    }
  case actionTypes.CLEAR_EXISTING_MEDIATOR:
    return {
      ...state,
      existingMediator: ''
    }
  case actionTypes.MEDIATOR_STATUS:
    return {
      ...state,
      isLoading: [...state.isLoading, "MEDIATOR_STATUS"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_STATUS'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_STATUS'})
    }
  case actionTypes.MEDIATOR_STATUS_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_STATUS'}),
      callSuccessful: [...state.isLoading, "MEDIATOR_STATUS"],
      mediators: [],
      mediator: action.payload.data
    }
  case actionTypes.MEDIATOR_STATUS_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_STATUS'}),
      loadingFailed: [...state.isLoading, "MEDIATOR_STATUS"],
      error: action.payload
    }
  case actionTypes.DELETE_MEDIATOR:
    return {
      ...state,
      isLoading: [...state.isLoading, "DELETE_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATOR'})
    }
  case actionTypes.DELETE_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "DELETE_MEDIATOR"]
    }
  case actionTypes.DELETE_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'DELETE_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "DELETE_MEDIATOR"],
      error: action.payload
    }
  default: 
    return state
  }
}