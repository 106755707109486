import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

export const fetchMediationsReport = () => {
  return () => {
    window.open(`${constants.url}/export/mediations?token=${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`);
  }
  // axios
  //   .get(`${constants.url}/export/mediations`, 
  //     { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
  //   .then(res =>  FileDownload(res.data, 'report.csv'))
  //   .catch(err => console.log('failure'))
}

export const fetchConfirmedMediationsReport = () => {
  return () => {
    window.open(`${constants.url}/export/mediations/assigned?token=${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`);
  }
}

export const fetchDeclinedMediationsReport = () => {
  return () => {
    window.open(`${constants.url}/export/mediations/confirmeddeclined?token=${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`);
  }
}

export const fetchMediatorMediationsReport = (id) => {
  return () => {
    window.open(`${constants.url}/export/mediator/mediations/${id}?token=${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`);
  }
}

export const fetchMediatorsReport = () => {
  return () => {
    window.open(`${constants.url}/export/mediators?token=${getValueFromLocal(constants['JWT_ACCESS_KEY'])}`);
  }
}

export const fetchMediatorActivityReport = (start_date, end_date) => {
  return () => {
    window.open(`${constants.url}/export/mediations/mediatorreport?token=${getValueFromLocal(constants['JWT_ACCESS_KEY'])}&start_date=${start_date}&end_date=${end_date}`);
  }
}
