import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Button, Image } from 'react-bootstrap';
import { fetchAllMediations, removeMediation } from 'redux/actions/mediation';
import { fetchMediationsReport } from 'redux/actions/report';
import moment from 'moment';
import Loader from "components/ui/Loader";
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';
import ConfirmationModal from 'components/ui/ConfirmationModal';

import { Certification } from 'components/ui/Certification';
import { Expertise } from 'components/ui/Expertise';

import DeleteImage from 'images/DeleteBlue.png';
import EditImage from 'images/EditBlue.png';

function ManageMediations(props){
  const role = getValueFromLocal(constants.ROLE);
  const [modalShow, setModalShow] = useState(false);
  const [mediationId, setMediationId] = useState(0);

  const { isLoading, isDeleted, mediations } = props;

  useEffect(() => {
    props.fetchAllMediations();
  }, [])

  if(isDeleted) {
    props.fetchAllMediations();
  }

  const editMediation = useCallback( id => {
    props.history.push(`/${role}/mediation/edit?id=${id}`);
  }, []);

  const canDelete = useCallback( id => {
    setModalShow(true);
    setMediationId(id);
  }, []);

  const confirmDeletion = useCallback( id => {
    setModalShow(false);
    props.removeMediation(id);
  }, []);

  const getReport = useCallback(() => {
    props.fetchMediationsReport();
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: '',
            id: 'edit',
            accessor: data => { return (<EditMediation id={data.id} edit={editMediation}/>) }
          },
          {
            Header: 'ADR Case Number',
            accessor: 'adr_case_number',
          },
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('hh:mm a');
            }
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Expertise',
            accessor: 'expertises',
            Cell: ({ cell: { value } }) => <Expertise values={value}/>
          },
          {
            Header: 'Mediator',
            accessor: 'mm_status[0].mediators',
            Cell: ({ cell: { value }  }) => {
              if (value)
                return value.first_name + ' ' + value.last_name;
              return '';
            }
          },
          {
            Header: '',
            accessor: 'id',
            Cell: ({ cell: { value } }) => <DeleteMediation id={value} delete={canDelete}/>
          }
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h2>Manage Mediations</h2>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={mediations}
              tableHeader={false} />
          </div>
          <Spacer size={15} />
          <Link to={`/${role}/mediation`}>
            <Button className="btn-second-alt" variant="secondary-alt" >
              Create New Mediation
            </Button>
          </Link>
          <Button className="btn-second-alt" variant="secondary-alt" onClick={getReport} >
            Export
          </Button>
          <ConfirmationModal
            show={modalShow}
            id={mediationId}
            onHide={() => setModalShow(false)}
            onConfirm={confirmDeletion}
          />
        </Container>
      </div>
    </>
  )
}

const EditMediation = (props) => {
  return(
    <>
      <Image className="editBlue" src={EditImage} onClick={() => props.edit(props.id)} />
    </>
  )
}

const DeleteMediation = (props) => {
  return(
    <>
      <Image className="deleteBlue" src={DeleteImage} onClick={() => props.delete(props.id)} />
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = state.mediationReducer.isLoading.includes('GET_MEDIATIONS') 
  || state.mediationReducer.isLoading.includes('DELETE_MEDIATION');
  const mediations = state.mediationReducer.mediations;
  const isDeleted = state.mediationReducer.callSuccessful.includes('DELETE_MEDIATION');
  return { isLoading, isDeleted, mediations }
}

export default connect(
  mapStateToProps,
  {
    fetchAllMediations,
    removeMediation,
    fetchMediationsReport
  }
)(ManageMediations)
