import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import constants from 'config/constants';
import Spacer from 'components/layout/Spacer';
import { Field, reduxForm } from "redux-form";
import { updateSchedulerPassword } from 'redux/actions/scheduler';
import { isSchedulerLoading } from 'redux/selectors';
import { required, passwordsMatch } from 'util/validationUtil';
import Loader from "components/ui/Loader";
import { Container, Row, Form, Col, Button } from 'react-bootstrap';
import { reduxFormInput } from 'components/form/ReduxForm';
import { getValueFromLocal } from 'util/storageUtil';

const SchedulerPasswordSet = (props) => {
  const mediatorId = getValueFromLocal(constants['USER_ID']);

  const { handleSubmit, isLoading, isPasswordSet } = props

  if (isPasswordSet) {
    props.history.push('/');
  }

  const submitFn = useCallback( data => {
    props.updateSchedulerPassword(mediatorId, data);
  }, [mediatorId]);

  return(
    <>
      { isLoading && <Loader /> }
      <main className="fc-content">
        <Spacer size={30} />
        <Container fluid>
          <Row>
            <Col xs="12">
              <h3>Change Password</h3>
              <Spacer size={15} />
            </Col>
            <Form id="createUI" className="w-100" onSubmit={handleSubmit(submitFn)}>
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="5">
                        Password:
                    </Form.Label>
                    <Col xs="12" sm="7">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="password" 
                        type="password" 
                        placeholder="Password..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="5">
                        Confirm Password:
                    </Form.Label>
                    <Col xs="12" sm="7">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, passwordsMatch]}
                        name="confirm_password" 
                        type="password" 
                        placeholder="Confirm Password..." 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={20} />
                  <Button type="submit" variant="primary-alt" size="lg">
                      Save
                  </Button>
                  <Spacer size={20} />
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </main>
    </>
  )

}

const mapStateToProps = state => {
  const isLoading = isSchedulerLoading(state);
  const isPasswordSet = state.schedulerReducer.callSuccessful.includes('UPDATE_SCHEDULER_PASSWORD');
  return { isLoading, isPasswordSet }
}
  
export default connect(
  mapStateToProps, 
  {
    updateSchedulerPassword
  })(
  reduxForm({
    form: "updateSchedulerPassword",
    enableReinitialize: true,
  })(SchedulerPasswordSet)
);