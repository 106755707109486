export const actionTypes = {
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  FORGOT_PASSWORD_RESET: 'FORGOT_PASSWORD_RESET',
  FORGOT_PASSWORD_RESET_SUCCESS: 'FORGOT_PASSWORD_RESET_SUCCESS',
  FORGOT_PASSWORD_RESET_FAILURE: 'FORGOT_PASSWORD_RESET_FAILURE',

  CHECK_PASSWORD_TOKEN: 'CHECK_PASSWORD_TOKEN',
  CHECK_PASSWORD_TOKEN_SUCCESS: 'CHECK_PASSWORD_TOKEN_SUCCESS',
  CHECK_PASSWORD_TOKEN_FAILURE: 'CHECK_PASSWORD_TOKEN_FAILURE',

  PAGINATE_TO: 'PAGINATE_TO',
  IS_FETCHING: 'IS_FETCHING',
  ERROR_FETCHING: 'ERROR_FETCHING',

  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',

  CREATE_MEDIATION: 'CREATE_MEDIATION',
  CREATE_MEDIATION_SUCCESS: 'CREATE_MEDIATION_SUCCESS',
  CREATE_MEDIATION_FAILURE: 'CREATE_MEDIATION_FAILURE',

  GET_MEDIATION: 'GET_MEDIATION',
  GET_MEDIATION_SUCCESS: 'GET_MEDIATION_SUCCESS',
  GET_MEDIATION_FAILURE: 'GET_MEDIATION_FAILURE',

  GET_MEDIATIONS: 'GET_MEDIATIONS',
  GET_MEDIATIONS_SUCCESS: 'GET_MEDIATIONS_SUCCESS',
  GET_MEDIATIONS_FAILURE: 'GET_MEDIATIONS_FAILURE',

  GET_NEW_MEDIATIONS: 'GET_NEW_MEDIATIONS',
  GET_NEW_MEDIATIONS_SUCCESS: 'GET_NEW_MEDIATIONS_SUCCESS',
  GET_NEW_MEDIATIONS_FAILURE: 'GET_NEW_MEDIATIONS_FAILURE',

  GET_NOTIFIED_MEDIATIONS: 'GET_NOTIFIED_MEDIATIONS',
  GET_NOTIFIED_MEDIATIONS_SUCCESS: 'GET_NOTIFIED_MEDIATIONS_SUCCESS',
  GET_NOTIFIED_MEDIATIONS_FAILURE: 'GET_NOTIFIED_MEDIATIONS_FAILURE',

  GET_ASSIGNED_MEDIATIONS: 'GET_ASSIGNED_MEDIATIONS',
  GET_ASSIGNED_MEDIATIONS_SUCCESS: 'GET_ASSIGNED_MEDIATIONS_SUCCESS',
  GET_ASSIGNED_MEDIATIONS_FAILURE: 'GET_ASSIGNED_MEDIATIONS_FAILURE',

  GET_DECLINED_MEDIATIONS: 'GET_DECLINED_MEDIATIONS',
  GET_DECLINED_MEDIATIONS_SUCCESS: 'GET_DECLINED_MEDIATIONS_SUCCESS',
  GET_DECLINED_MEDIATIONS_FAILURE: 'GET_DECLINED_MEDIATIONS_FAILURE',

  GET_MEDIATOR_ASSIGNED_MEDIATIONS: 'GET_MEDIATOR_ASSIGNED_MEDIATIONS',
  GET_MEDIATOR_ASSIGNED_MEDIATIONS_SUCCESS: 'GET_MEDIATOR_ASSIGNED_MEDIATIONS_SUCCESS',
  GET_MEDIATOR_ASSIGNED_MEDIATIONS_FAILURE: 'GET_MEDIATOR_ASSIGNED_MEDIATIONS_FAILURE',

  GET_MCONFIRMED_DECLINED_MEDIATIONS: 'GET_MCONFIRMED_DECLINED_MEDIATIONS',
  GET_MCONFIRMED_DECLINED_MEDIATIONS_SUCCESS: 'GET_MCONFIRMED_DECLINED_MEDIATIONS_SUCCESS',
  GET_MCONFIRMED_DECLINED_MEDIATIONS_FAILURE: 'GET_MCONFIRMED_DECLINED_MEDIATIONS_FAILURE',

  GET_UPCOMING_MEDIATIONS: 'GET_UPCOMING_MEDIATIONS',
  GET_UPCOMING_MEDIATIONS_SUCCESS: 'GET_UPCOMING_MEDIATIONS_SUCCESS',
  GET_UPCOMING_MEDIATIONS_FAILURE: 'GET_UPCOMING_MEDIATIONS_FAILURE',

  GET_RECENT_MEDIATIONS: 'GET_RECENT_MEDIATIONS',
  GET_RECENT_MEDIATIONS_SUCCESS: 'GET_RECENT_MEDIATIONS_SUCCESS',
  GET_RECENT_MEDIATIONS_FAILURE: 'GET_RECENT_MEDIATIONS_FAILURE',

  GET_MEDIATION_REPORT: 'GET_MEDIATION_REPORT',
  GET_MEDIATION_REPORT_SUCCESS: 'GET_MEDIATION_REPORT_SUCCESS',
  GET_MEDIATION_REPORT_FAILURE: 'GET_MEDIATION_REPORT_FAILURE',

  GET_CONFIRMATION_RATE: 'GET_CONFIRMATION_RATE',
  GET_CONFIRMATION_RATE_SUCCESS: 'GET_CONFIRMATION_RATE_SUCCESS',
  GET_CONFIRMATION_RATE_FAILURE: 'GET_CONFIRMATION_RATE_FAILURE',

  UPDATE_MEDIATION: 'UPDATE_MEDIATION',
  UPDATE_MEDIATION_SUCCESS: 'UPDATE_MEDIATION_SUCCESS',
  UPDATE_MEDIATION_FAILURE: 'UPDATE_MEDIATION_FAILURE',

  DELETE_MEDIATION: 'DELETE_MEDIATION',
  DELETE_MEDIATION_SUCCESS: 'DELETE_MEDIATION_SUCCESS',
  DELETE_MEDIATION_FAILURE: 'DELETE_MEDIATION_FAILURE',

  CREATE_MEDIATOR: 'CREATE_MEDIATOR',
  CREATE_MEDIATOR_SUCCESS: 'CREATE_MEDIATOR_SUCCESS',
  CREATE_MEDIATOR_FAILURE: 'CREATE_MEDIATOR_FAILURE',

  GET_MEDIATOR: 'GET_MEDIATOR',
  GET_MEDIATOR_SUCCESS: 'GET_MEDIATOR_SUCCESS',
  GET_MEDIATOR_FAILURE: 'GET_MEDIATOR_FAILURE',

  GET_MEDIATOR_IMAGE: 'GET_MEDIATOR_IMAGE',
  GET_MEDIATOR_IMAGE_SUCCESS: 'GET_MEDIATOR_IMAGE_SUCCESS',
  GET_MEDIATOR_IMAGE_FAILURE: 'GET_MEDIATOR_IMAGE_FAILURE',

  GET_MEDIATORS: 'GET_MEDIATORS',
  GET_MEDIATORS_SUCCESS: 'GET_MEDIATORS_SUCCESS',
  GET_MEDIATORS_FAILURE: 'GET_MEDIATORS_FAILURE',

  UPDATE_MEDIATOR: 'UPDATE_MEDIATOR',
  UPDATE_MEDIATOR_SUCCESS: 'UPDATE_MEDIATOR_SUCCESS',
  UPDATE_MEDIATOR_FAILURE: 'UPDATE_MEDIATOR_FAILURE',

  SELF_UPDATE_MEDIATOR: 'SELF_UPDATE_MEDIATOR',
  SELF_UPDATE_MEDIATOR_SUCCESS: 'SELF_UPDATE_MEDIATOR_SUCCESS',
  SELF_UPDATE_MEDIATOR_FAILURE: 'SELF_UPDATE_MEDIATOR_FAILURE',

  UPDATE_MEDIATOR_PASSWORD: 'UPDATE_MEDIATOR_PASSWORD',
  UPDATE_MEDIATOR_PASSWORD_SUCCESS: 'UPDATE_MEDIATOR_PASSWORD_SUCCESS',
  UPDATE_MEDIATOR_PASSWORD_FAILURE: 'UPDATE_MEDIATOR_PASSWORD_FAILURE',

  CLEAR_EXISTING_MEDIATOR: 'CLEAR_EXISTING_MEDIATOR',
  ALLOW_MEDIATOR_PASSWORD_CHANGE: 'ALLOW_MEDIATOR_PASSWORD_CHANGE',
  ALLOW_SCHEDULER_PASSWORD_CHANGE: 'ALLOW_SCHEDULER_PASSWORD_CHANGE',

  DELETE_MEDIATOR: 'DELETE_MEDIATOR',
  DELETE_MEDIATOR_SUCCESS: 'DELETE_MEDIATOR_SUCCESS',
  DELETE_MEDIATOR_FAILURE: 'DELETE_MEDIATOR_FAILURE',

  CREATE_ADMINISTRATOR: 'CREATE_ADMINISTRATOR',
  CREATE_ADMINISTRATOR_SUCCESS: 'CREATE_ADMINISTRATOR_SUCCESS',
  CREATE_ADMINISTRATOR_FAILURE: 'CREATE_ADMINISTRATOR_FAILURE',

  GET_ADMINISTRATORS: 'GET_ADMINISTRATORS',
  GET_ADMINISTRATORS_SUCCESS: 'GET_ADMINISTRATORS_SUCCESS',
  GET_ADMINISTRATORS_FAILURE: 'GET_ADMINISTRATORS_FAILURE',

  UPDATE_ADMINISTRATOR: 'UPDATE_ADMINISTRATOR',
  UPDATE_ADMINISTRATOR_SUCCESS: 'UPDATE_ADMINISTRATOR_SUCCESS',
  UPDATE_ADMINISTRATOR_FAILURE: 'UPDATE_ADMINISTRATOR_FAILURE',

  UPDATE_ADMIN_PASSWORD: 'UPDATE_ADMIN_PASSWORD',
  UPDATE_ADMIN_PASSWORD_SUCCESS: 'UPDATE_ADMIN_PASSWORD_SUCCESS',
  UPDATE_ADMIN_PASSWORD_FAILURE: 'UPDATE_ADMIN_PASSWORD_FAILURE',

  DELETE_ADMINISTRATOR: 'DELETE_ADMINISTRATOR',
  DELETE_ADMINISTRATOR_SUCCESS: 'DELETE_ADMINISTRATOR_SUCCESS',
  DELETE_ADMINISTRATOR_FAILURE: 'DELETE_ADMINISTRATOR_FAILURE',

  CREATE_SCHEDULER: 'CREATE_SCHEDULER',
  CREATE_SCHEDULER_SUCCESS: 'CREATE_SCHEDULER_SUCCESS',
  CREATE_SCHEDULER_FAILURE: 'CREATE_SCHEDULER_FAILURE',

  GET_SCHEDULERS: 'GET_SCHEDULERS',
  GET_SCHEDULERS_SUCCESS: 'GET_SCHEDULERS_SUCCESS',
  GET_SCHEDULERS_FAILURE: 'GET_SCHEDULERS_FAILURE',

  UPDATE_SCHEDULER: 'UPDATE_SCHEDULER',
  UPDATE_SCHEDULER_SUCCESS: 'UPDATE_SCHEDULER_SUCCESS',
  UPDATE_SCHEDULER_FAILURE: 'UPDATE_SCHEDULER_FAILURE',

  UPDATE_SCHEDULER_PASSWORD: 'UPDATE_SCHEDULER_PASSWORD',
  UPDATE_SCHEDULER_PASSWORD_SUCCESS: 'UPDATE_SCHEDULER_PASSWORD_SUCCESS',
  UPDATE_SCHEDULER_PASSWORD_FAILURE: 'UPDATE_SCHEDULER_PASSWORD_FAILURE',

  DELETE_SCHEDULER: 'DELETE_SCHEDULER',
  DELETE_SCHEDULER_SUCCESS: 'DELETE_SCHEDULER_SUCCESS',
  DELETE_SCHEDULER_FAILURE: 'DELETE_SCHEDULER_FAILURE',

  GET_MEDIATION_MA:'GET_MEDIATION_MA',
  GET_MEDIATION_MA_SUCCESS: 'GET_MEDIATION_MA_SUCCESS',
  GET_MEDIATION_MA_FAILURE: 'GET_MEDIATION_MA_FAILURE',

  CREATE_CERTIFICATION: 'CREATE_CERTIFICATION',
  CREATE_CERTIFICATION_SUCCESS: 'CREATE_CERTIFICATION_SUCCESS',
  CREATE_CERTIFICATION_FAILURE: 'CREATE_CERTIFICATION_FAILURE',

  GET_CERTIFICATIONS: 'GET_CERTIFICATIONS',
  GET_CERTIFICATIONS_SUCCESS: 'GET_CERTIFICATIONS_SUCCESS',
  GET_CERTIFICATIONS_FAILURE: 'GET_CERTIFICATIONS_FAILURE',

  UPDATE_CERTIFICATION: 'UPDATE_CERTIFICATION',
  UPDATE_CERTIFICATION_SUCCESS: 'UPDATE_CERTIFICATION_SUCCESS',
  UPDATE_CERTIFICATION_FAILURE: 'UPDATE_CERTIFICATION_FAILURE',

  DELETE_CERTIFICATION: 'DELETE_CERTIFICATION',
  DELETE_CERTIFICATION_SUCCESS: 'DELETE_CERTIFICATION_SUCCESS',
  DELETE_CERTIFICATION_FAILURE: 'DELETE_CERTIFICATION_FAILURE',

  CREATE_EXPERTISE: 'CREATE_EXPERTISE',
  CREATE_EXPERTISE_SUCCESS: 'CREATE_EXPERTISE_SUCCESS',
  CREATE_EXPERTISE_FAILURE: 'CREATE_EXPERTISE_FAILURE',

  GET_EXPERTISES: 'GET_EXPERTISES',
  GET_EXPERTISES_SUCCESS: 'GET_EXPERTISES_SUCCESS',
  GET_EXPERTISES_FAILURE: 'GET_EXPERTISES_FAILURE',

  UPDATE_EXPERTISE: 'UPDATE_EXPERTISE',
  UPDATE_EXPERTISE_SUCCESS: 'UPDATE_EXPERTISE_SUCCESS',
  UPDATE_EXPERTISE_FAILURE: 'UPDATE_EXPERTISE_FAILURE',

  DELETE_EXPERTISE: 'DELETE_EXPERTISE',
  DELETE_EXPERTISE_SUCCESS: 'DELETE_EXPERTISE_SUCCESS',
  DELETE_EXPERTISE_FAILURE: 'DELETE_EXPERTISE_FAILURE',

  SAVE_MEDIATION_MEDIATOR: 'SAVE_MEDIATION_MEDIATOR',
  SAVE_MEDIATION_MEDIATOR_SUCCESS: 'SAVE_MEDIATION_MEDIATOR_SUCCESS',
  SAVE_MEDIATION_MEDIATOR_FAILURE: 'SAVE_MEDIATION_MEDIATOR_FAILURE',

  UPDATE_MEDIATION_MEDIATOR: 'UPDATE_MEDIATION_MEDIATOR',
  UPDATE_MEDIATION_MEDIATOR_SUCCESS: 'UPDATE_MEDIATION_MEDIATOR_SUCCESS',
  UPDATE_MEDIATION_MEDIATOR_FAILURE: 'UPDATE_MEDIATION_MEDIATOR_FAILURE',

  MEDIATOR_MEDIATION_UPDATE: 'MEDIATOR_MEDIATION_UPDATE',
  MEDIATOR_MEDIATION_UPDATE_SUCCESS: 'MEDIATOR_MEDIATION_UPDATE_SUCCESS',
  MEDIATOR_MEDIATION_UPDATE_FAILURE: 'MEDIATOR_MEDIATION_UPDATE_FAILURE',

  NOTIFY_MEDIATOR: 'NOTIFY_MEDIATOR',
  NOTIFY_MEDIATOR_SUCCESS: 'NOTIFY_MEDIATOR_SUCCESS',
  NOTIFY_MEDIATOR_FAILURE: 'NOTIFY_MEDIATOR_FAILURE',

  MEDIATOR_STATUS: 'MEDIATOR_STATUS',
  MEDIATOR_STATUS_SUCCESS: 'MEDIATOR_STATUS_SUCCESS',
  MEDIATOR_STATUS_FAILURE: 'MEDIATOR_STATUS_FAILURE',

  MEDIATOR_REMOVED: 'MEDIATOR_REMOVED',
  CANCEL_MEDIATION_MEDIATOR: 'CANCEL_MEDIATION_MEDIATOR',
  CANCEL_MEDIATION_MEDIATOR_SUCCESS: 'CANCEL_MEDIATION_MEDIATOR_SUCCESS',
  CANCEL_MEDIATION_MEDIATOR_FAILURE: 'CANCEL_MEDIATION_MEDIATOR_FAILURE',

  SEARCH_MEDIATOR:'SEARCH_MEDIATOR',
  SEARCH_MEDIATOR_SUCCESS:'SEARCH_MEDIATOR_SUCCESS',
  SEARCH_MEDIATOR_FAILURE:'SEARCH_MEDIATOR_FAILURE',

  CREATE_MEDIATOR_AVAILABILITY_SUCCESS: 'CREATE_MEDIATOR_AVAILABILITY_SUCCESS',
  CREATE_MEDIATOR_AVAILABILITY_FAILURE: 'CREATE_MEDIATOR_AVAILABILITY_FAILURE',

  MEDIATOR_AVAILABILITY_SUCCESS: 'MEDIATOR_AVAILABILITY_SUCCESS',
  MEDIATOR_AVAILABILITY_FAILURE: 'MEDIATOR_AVAILABILITY_FAILURE',
  
  MEDIATORS_FOR_MONTH_SUCCESS: 'MEDIATORS_FOR_MONTH_SUCCESS',
  MEDIATORS_FOR_MONTH_FAILURE:'MEDIATORS_FOR_MONTH_FAILURE',

  GET_MEDIATOR_LIST:'GET_MEDIATOR_LIST',
  GET_MEDIATOR_LIST_FAILURE:'GET_MEDIATOR_LIST_FAILURE',

  GET_DELETE_AVAILABILITY_SUCCESS:'GET_DELETE_AVAILABILITY_SUCCESS',
  GET_DELETE_AVAILABILITY_FAILURE:'GET_DELETE_AVAILABILITY_FAILURE'
};
