import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isCalling: [],
  callFailed: [],
  callSucessful: [],
  expertises: [],
  expertise: '',
  error: ''
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CREATE_EXPERTISE:
    return {
      ...state,
      isCalling: ['CREATE_EXPERTISE'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.CREATE_EXPERTISE_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['CREATE_EXPERTISE'],
      expertise: action.payload
    }
  case actionTypes.CREATE_EXPERTISE_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['CREATE_EXPERTISE'],
      error: action.payload
    }
  case actionTypes.GET_EXPERTISES:
    return {
      ...state,
      isCalling: ['GET_EXPERTISES'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.GET_EXPERTISES_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['GET_EXPERTISES'],
      expertises: action.payload.data
    }
  case actionTypes.GET_EXPERTISES_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['GET_EXPERTISES'],
      error: action.payload
    }
  case actionTypes.UPDATE_EXPERTISE:
    return {
      ...state,
      isCalling: ['UPDATE_EXPERTISE'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.UPDATE_EXPERTISE_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['UPDATE_EXPERTISE'],
      expertise: action.payload.data
    }
  case actionTypes.UPDATE_EXPERTISE_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['UPDATE_EXPERTISE'],
      error: action.payload
    }
  case actionTypes.DELETE_EXPERTISE:
    return {
      ...state,
      isCalling: ['DELETE_EXPERTISE'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  case actionTypes.DELETE_EXPERTISE_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSucessful: ['DELETE_EXPERTISE'],
    }
  case actionTypes.DELETE_EXPERTISE_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['DELETE_EXPERTISE'],
      error: action.payload
    }
  default: 
    return state
  }
}