import React from 'react';
import Logo from 'images/FccLogoWhite.png'
import { withRouter, Link } from 'react-router-dom';
import { Image, Container, Row, Col } from 'react-bootstrap';

import { destroyLocal } from 'util/storageUtil';

class Header extends React.Component {

  logoutHandler = e => {
    e.preventDefault();
    destroyLocal();
    // return <Redirect to='/' />
    this.props.history.push('/');
  }

  render() {
    const { type } = this.props;
    return(
      <header>
        <Container fluid>
          <Row>
            <Col xs="2">
              <Link to="/" className="login_logo" >
                <Image className="logo" src={Logo} />
              </Link>
            </Col>
            <Col className="title" xs md="9">
              <h1>{type}</h1>
            </Col>
          </Row>
        </Container>
      </header>
    )
  }
}

export default withRouter(Header)