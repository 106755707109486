import React from 'react';
import Spacer from 'components/layout/Spacer';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { allowSchedulerPasswordChange } from 'redux/actions/scheduler';
import { connect } from 'react-redux';

function SchedulerPanel(props) {
  
  props.allowSchedulerPasswordChange();

  return(
    <>
      <div className="fc-content">
        <Spacer size={30} />
        <Container fluid>
          <Row>
            <Col xs="12" md="10">
              <Row className="text-center" >
                <Col className="dashboard-items" >
                  <Link to="/scheduler/manage/mediations">
                    <Button variant="secondary">
                      Manage Mediations
                    </Button>
                  </Link>
                  <Spacer size={10} />
                  <Link to="/scheduler/schedule/mediations">
                    <Button variant="secondary">
                      Schedule Mediations
                    </Button>
                  </Link>
                  <Spacer size={10} />
                  <Link to="/scheduler/setpassword">
                    <Button variant="secondary">
                      Change Password
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col md="2"></Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default connect(
  null, 
  {
    allowSchedulerPasswordChange
  })(SchedulerPanel);
