import { actionTypes } from '../actions/actionTypes';

const initialState = {
  currentPageIndex: 0
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.PAGINATE_TO: 
  {
    return {
      ...state,
      currentPageIndex: action.payload
    }
  }
  default: 
    return state
  }
}