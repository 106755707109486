import Panel from 'containers/admin/Panel';
import ManageMediations from 'containers/admin/ManageMediations';
import ScheduleMediations from 'containers/admin/ScheduleMediations';
import ViewMediatorAvailability from 'containers/adminScheduler/ViewMediatorAvailability';
import ConfirmedMediations from 'containers/admin/ViewConfirmations';
import DeclinedMediations from 'containers/admin/ViewDeclined';
import ManageMediators from 'containers/admin/ManageMediators';
import ViewAllMediators from 'containers/admin/ViewAllMediators';
import CreateNewMediator from 'containers/admin/CreateNewMediator';
import EditMediator from 'containers/admin/EditMediator';
import ManageAdministrators from 'containers/admin/ManageAdministrators';
import ManageSchedulers from 'containers/admin/ManageSchedulers';
import ManageExpertises from 'containers/admin/ManageExpertises';
import ManageCertifications from 'containers/admin/ManageCertifications';
import MediatorActivityReport from 'containers/admin/MediatorActivityReport';
import CreateNewMediation from 'containers/admin/CreateNewMediation';
import EditMediation from 'containers/admin/EditMediation';

const adminRoutes = [
  {
    path: "/panel",
    component: Panel,
    name: "Admin",
    layout: "/admin"
  },
  {
    path: "/manage/mediations",
    component: ManageMediations,
    name: "Manage Mediations",
    layout: "/admin"
  },
  {
    path: "/schedule/mediations",
    component: ScheduleMediations,
    name: "Schedule Mediations",
    layout: "/admin"
  },
  {
    path: "/mediator/availability",
    component: ViewMediatorAvailability,
    name: "View Mediator Availability",
    layout: "/admin"
  },
  {
    path: "/mediation/confirmations",
    component: ConfirmedMediations,
    name: "View Confirmations",
    layout: "/admin"
  },
  {
    path: "/mediation/declined",
    component: DeclinedMediations,
    name: "View Declined",
    layout: "/admin"
  },
  {
    path: "/mediation/edit",
    component: EditMediation,
    name: "Edit Mediation",
    layout: "/admin"
  },
  {
    path: "/mediation",
    component: CreateNewMediation,
    name: "Mediation",
    layout: "/admin"
  },
  {
    path: "/manage/mediators",
    component: ManageMediators,
    name: "Manage Mediators",
    layout: "/admin"
  },
  {
    path: "/all/mediators",
    component: ViewAllMediators,
    name: "View All Mediators",
    layout: "/admin"
  },
  {
    path: "/mediator/edit",
    component: EditMediator,
    name: "Mediator",
    layout: "/admin"
  },
  {
    path: "/mediator",
    component: CreateNewMediator,
    name: "Mediator",
    layout: "/admin"
  },
  {
    path: "/manage/administrators",
    component: ManageAdministrators,
    name: "Manage Administrators",
    layout: "/admin"
  },
  {
    path: "/manage/schedulers",
    component: ManageSchedulers,
    name: "Manage Schedulers",
    layout: "/admin"
  },
  {
    path: "/manage/expertises",
    component: ManageExpertises,
    name: "Manage Expertises",
    layout: "/admin"
  },
  {
    path: "/manage/certifications",
    component: ManageCertifications,
    name: "Manage Certifications",
    layout: "/admin"
  },
  {
    path: "/activityreport",
    component: MediatorActivityReport,
    name: "Mediator Activity Report",
    layout: "/admin"
  }
];

export { adminRoutes };
