import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isCalling: [],
  callFailed: [],
  callSuccessful: [],
  schedulers: [],
  scheduler: '',
  error: ''
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CREATE_SCHEDULER:
    return {
      ...state,
      isCalling: ['CREATE_SCHEDULER'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.CREATE_SCHEDULER_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['CREATE_SCHEDULER'],
      scheduler: action.payload
    }
  case actionTypes.CREATE_SCHEDULER_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['CREATE_SCHEDULER'],
      error: action.payload
    }
  case actionTypes.GET_SCHEDULERS:
    return {
      ...state,
      isCalling: ['GET_SCHEDULERS'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.GET_SCHEDULERS_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['GET_SCHEDULERS'],
      schedulers: action.payload.data
    }
  case actionTypes.GET_SCHEDULERS_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['GET_SCHEDULERS'],
      error: action.payload
    }
  case actionTypes.UPDATE_SCHEDULER:
    return {
      ...state,
      isCalling: ['UPDATE_SCHEDULER'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.UPDATE_SCHEDULER_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['UPDATE_SCHEDULER'],
      scheduler: action.payload.data
    }
  case actionTypes.UPDATE_SCHEDULER_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['UPDATE_SCHEDULER'],
      error: action.payload
    }
  case actionTypes.UPDATE_SCHEDULER_PASSWORD:
    return {
      ...state,
      isCalling: ['UPDATE_SCHEDULER_PASSWORD'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.UPDATE_SCHEDULER_PASSWORD_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['UPDATE_SCHEDULER_PASSWORD'],
      scheduler: action.payload.data
    }
  case actionTypes.UPDATE_SCHEDULER_PASSWORD_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['UPDATE_SCHEDULER_PASSWORD'],
      error: action.payload
    }
  case actionTypes.ALLOW_SCHEDULER_PASSWORD_CHANGE:
    return {
      ...state,
      callSuccessful: state.isCalling.filter(function(value){ return value !== 'UPDATE_SCHEDULER_PASSWORD'})
    }
  case actionTypes.DELETE_SCHEDULER:
    return {
      ...state,
      isCalling: ['DELETE_SCHEDULER'],
      callFailed: [],
      callSuccessful: [],
      error: ''
    }
  case actionTypes.DELETE_SCHEDULER_SUCCESS:
    return {
      ...state,
      isCalling: [],
      callSuccessful: ['DELETE_SCHEDULER'],
    }
  case actionTypes.DELETE_SCHEDULER_FAILURE:
    return {
      ...state,
      isCalling: [],
      callFailed: ['DELETE_SCHEDULER'],
      error: action.payload
    }
  default: 
    return state
  }
}