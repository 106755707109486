import { combineReducers } from 'redux';
import administratorReducer from './administratorReducer.js';
import authReducer from './authReducer.js';
import certificationReducer from './certificationReducer.js';
import expertiseReducer from './expertiseReducer.js';
import mediationReducer from './mediationReducer.js';
import mediatorAvailabilityReducer from './mediatorAvailabilityReducer.js';
import mediatorReducer from './mediatorReducer.js';
import mmStatusReducer from './mmStatusReducer.js';
import paginate from './paginate.js';
import reportReducer from './reportReducer.js';
import schedulerReducer from './schedulerReducer.js';
// import { saveValueInLocal } from 'util/storageUtil';
import { reducer as reduxFormReducer } from 'redux-form';
// import constants from 'config/constants';
// console.log('index called 1'+window.location.href)

// let url = window.location.href;
// if(url.includes("https://adrmediationfrontend.fultoncourt.org/")){
//   saveValueInLocal(constants.ENVIRONMENT, "DEVELOPMENT");
// }
 

const appReducer = combineReducers({
  form: reduxFormReducer,
  administratorReducer,
  authReducer,
  certificationReducer,
  expertiseReducer,
  mediationReducer,
  mediatorAvailabilityReducer,
  mediatorReducer,
  mmStatusReducer,
  paginate,
  reportReducer,
  schedulerReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
}

export default rootReducer;
