import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isCalling: []
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_MEDIATIONS_REPORT:
    return {
      ...state,
      isCalling: ['GET_MEDIATIONS_REPORT'],
      callFailed: [],
      callSucessful: [],
      error: ''
    }
  default: 
    return state
  }

}
