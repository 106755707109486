import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Row, Col, Button, Image, Modal, Form } from 'react-bootstrap';
import { Field, reduxForm, reset } from "redux-form";
import { addCertification, modifyCertification, fetchCertifications, removeCertification } from 'redux/actions/certification';
import Loader from "components/ui/Loader";
import ConfirmationModal from 'components/ui/ConfirmationModal';
import { isCertificationLoading } from 'redux/selectors';
import DeleteImage from 'images/DeleteWhite.png';
import EditImage from 'images/EditWhite.png';
import checkImage from 'images/check.png';
import uncheckImage from 'images/uncheck.png';
import { reduxFormInput } from 'components/form/ReduxForm';
import { required } from 'util/validationUtil';
import EditCertification from 'containers/modal/EditCertification';

function ManageCertifications(props){
  const [modalShow, setModalShow] = useState(false);
  const [certShow, setCertShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [certificationId, setCertificationId] = useState(0);

  const { handleSubmit, isLoading, certifications, certification, isDeleted } = props;

  const initialValues = certifications.find(cert => cert.id === certificationId);
  if(initialValues) initialValues.is_active = (initialValues.is_active === '1' || initialValues.is_active === 1) ? 1 : 0;

  useEffect(() => {
    props.fetchCertifications();
  }, [certification, isDeleted])

  const editCert = useCallback(id => {
    setCertificationId(id);
    setEditShow(true);
  }, [initialValues]);

  const canDelete = useCallback( id => {
    setModalShow(true);
    setCertificationId(id);
  }, []);

  const confirmDeletion = useCallback( id => {
    setModalShow(false);
    props.removeCertification(id);
  }, []);

  const createCert = useCallback( () => {
    setCertShow(true);
  }, []);

  const submitFn = useCallback( data => {
    props.addCertification(data);
    setCertShow(false);
  }, []);

  const clearFields = useCallback( () => {
    setCertShow(false);
    props.dispatch(reset('createCert'));
  }, []);

  const editSubmit = useCallback( data => {
    props.modifyCertification(data.id, data);
    setEditShow(false);
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: '',
            accessor: 'id',
            Cell: ({ cell: { value } }) => <EditCell id={value} delete={canDelete} edit={editCert} />
          },
          {
            Header: 'Certification Type',
            accessor: 'certification_type'
          },
          {
            Header: 'Certification Id',
            accessor: 'certification_id'
          },
          {
            Header: 'Active',
            accessor: 'is_active',
            Cell: ({ cell: { value } }) => <CheckStatus status={value}/>
          }
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Manage Certifications</h3>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={certifications}
              tableHeader={false} />
          </div>
          <Spacer size={3} />
          <Button className="btn-second-alt" variant="secondary-alt" onClick={createCert} >
            Add New Certification
          </Button>
          <AddCertification
            show={certShow}
            onHide={clearFields}
            onSubmit={handleSubmit(submitFn)}
          />
          <EditCertification 
            show={editShow}
            onHide={() => setEditShow(false)}
            update={editSubmit}
            initialValues={initialValues}
          />
          <ConfirmationModal
            show={modalShow}
            id={certificationId}
            onHide={() => setModalShow(false)}
            onConfirm={confirmDeletion}
          />
        </Container>
      </div>
    </>
  )
}

const EditCell = (props) => {
  return(
    <>
      <Image className="editdelete" src={EditImage} onClick={() => props.edit(props.id)} />
      <Image className="editdelete" src={DeleteImage} onClick={() => props.delete(props.id)} />
    </>
  )
}

const CheckStatus = (props) => {
  return(
    <>
      { props.status === 1 || props.status === '1' ?
        <Image className="checkuncheck" src={checkImage}/> : 
        <Image className="checkuncheck" src={uncheckImage}/> }
    </>
  )
}

const AddCertification = (props) => {
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Add New Certification</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container fluid>
            <Spacer size={3} />
            <Form id="createCertUI" className="w-100" >
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      Certification Type:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="certification_type" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      Certification ID:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="certification_id" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={2} />
                  <Button type="submit" className="primary" variant="primary-alt" size="sm">
                    Save
                  </Button>
                  <Spacer size={2} />
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => {
  const isLoading = isCertificationLoading(state);
  const certifications = state.certificationReducer.certifications;
  const certification = state.certificationReducer.certification;
  const isDeleted = state.certificationReducer.callSucessful.includes('DELETE_CERTIFICATION');
  return { isLoading, certifications, certification, isDeleted }
}

const connectedReduxForm = reduxForm({
  form: 'createCert',
  enableReinitialize: true,
})(ManageCertifications);

export default connect(
  mapStateToProps,
  {
    fetchCertifications,
    removeCertification,
    addCertification,
    modifyCertification
  }
)(connectedReduxForm)
