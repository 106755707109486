import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import moment from 'moment';
import CheckboxGroup from 'components/form/CheckboxGroup';
import DisabledCheckboxGroup from 'components/form/DisabledCheckboxGroup';
import { communication_method, states} from 'util/dataUtil';
import { Field, reduxForm } from "redux-form";
import { Card, Container, Row, Form, Col, Button } from 'react-bootstrap';
import { addMediator, modifySelfMediator } from 'redux/actions/mediator';
import { fetchCertifications } from 'redux/actions/certification';
import { fetchExpertises } from 'redux/actions/expertise';
import { fetchMediator, uploadMediatorImage, downloadMediatorImage } from 'redux/actions/mediator';
import { fetchUpcomingMediations, fetchConfirmationRate } from 'redux/actions/mediation';
import { required, email, mobileNumber, number } from 'util/validationUtil';
import { reduxFormInput, reduxFormTextarea, reduxFormSelect } from 'components/form/ReduxForm';
import { isCertificationLoading, isExpertiseLoading, isMediatorLoading } from 'redux/selectors';
import Loader from "components/ui/Loader";
import { Certification } from 'components/ui/Certification';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

const ViewProfile = (props) => {
  const mediatorId = getValueFromLocal(constants['USER_ID']);
  let imageUpload = React.createRef();
  const [uploadedFile, setUploadedFile] = useState();
  const [img, setImg] = useState();
  const [newImage, setNewImage] = useState(false);

  const { 
    handleSubmit, 
    isLoading, 
    certifications, 
    expertises, 
    initialValues, 
    existingImage, 
    imageUploaded, 
    mediatorUpdated,
    upcomingMediations,
    rate
  } = props;

  if (mediatorUpdated && newImage) {
    const formData = new FormData();
    const imagedata = img;
    formData.append('PROFILE_PHOTO', imagedata);
    props.uploadMediatorImage(mediatorId, formData);
  } else if (imageUploaded || mediatorUpdated) {
    props.history.push('/mediator/panel');
  }

  useEffect(() => {
    props.fetchCertifications();
    props.fetchExpertises();
    props.fetchUpcomingMediations(mediatorId);
    props.fetchMediator(mediatorId);
    props.downloadMediatorImage(mediatorId);
    props.fetchConfirmationRate(mediatorId);
  }, [])

  const form_certifications = [];
  certifications.forEach( certificate => 
    form_certifications.push({ label: certificate.certification_type, value: certificate.certification_type })
  );

  const form_expertises = [];
  expertises.forEach( expertise => 
    form_expertises.push({ label: expertise.name, value: expertise.name })
  );

  if(initialValues) {
    const certificationsArray = [];
    initialValues.certificates.forEach(c => {
      if(c.certification) certificationsArray.push(c.certification.certification_type);
    });
    initialValues.certifications = certificationsArray;

    const expertisesArray = [];
    initialValues.expertises.forEach(e => {
      if(e.expertise) expertisesArray.push(e.expertise.name);
    });
    initialValues.expertizes = expertisesArray;
    
    if(initialValues.communication_method === 'both') {
      initialValues.communication = ['Email', 'Text message'];
    } else {
      initialValues.communication = [initialValues.communication_method];
    }
  }

  useEffect(() => {
    setUploadedFile(existingImage);
  }, [existingImage])

  const submitFn = useCallback( data => {
    data.state = data.state.value ? data.state.value : initialValues.state;
    // const temp_certificates = [];
    // data.certifications.forEach(certificate => {
    //   const temp = certifications.find(cert =>  cert.certification_type === certificate );
    //   if (temp) temp_certificates.push(temp.id);
    // });
    // data.certification_ids = temp_certificates.join();
    // const temp_expertises = [];
    // data.expertizes.forEach(expertise => {
    //   const temp = expertises.find(expert => expert.name === expertise);
    //   if (temp) temp_expertises.push(temp.id);
    // });
    // data.expertise_ids = temp_expertises.join();

    if(data.communication.length === 2) {
      data.communication_method = 'both';
    } else {
      data.communication_method = data.communication[0];
    }

    props.modifySelfMediator(mediatorId, data);

  }, [initialValues, certifications, expertises, img]);

  const handleFileChange = useCallback (e => {
    setUploadedFile(URL.createObjectURL(e.target.files[0]));
    setNewImage(true);
  }, []);

  useEffect(() => {
    setImg(imageUpload.current.files[0]);
  }, [uploadedFile])

  const columns = useMemo(
    () => [
      {
        Header: 'Upcoming Appointments',
        columns: [
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('HH:mm');
            }
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Confirmed',
            accessor: 'mm_status[0].status',
            Cell: ({ cell: { value } }) => <CheckStatus status={value}/>
          },
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <main className="fc-content">
        <Spacer size={30} />
        <Container fluid>
          <Row>
            <Col xs="12">
              <h3>Profile</h3>
              <Spacer size={15} />
            </Col>
            <Form id="createUI" className="w-100" onSubmit={handleSubmit(submitFn)}>
              <Row>
                <Col xs="12" sm="6">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                      Add Image:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <div className="upload-wrapper" style={uploadedFile !== null ? {"backgroundImage": `url(${uploadedFile})`} : {"": ""}}>
                        <Button variation="primary-img"></Button>
                        <input ref={imageUpload} type="file" name="IMAGE" accept=".png, .jpg, .jpeg" onChange={handleFileChange}/>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        First Name:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="first_name" 
                        type="text" 
                        placeholder="First name..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Last Name:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="last_name" 
                        type="text" 
                        placeholder="Last name..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Mediator Id:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component="input"
                        name="mediator_id" 
                        type="text" 
                        disabled={true}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Email Address:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, email]}
                        name="email" 
                        type="email" 
                        placeholder="Email address..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Phone Number:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, mobileNumber]}
                        name="phone_number" 
                        type="text" 
                        placeholder="Phone number..."
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Notes:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormTextarea}
                        name="notes" 
                        rows="3" 
                        placeholder="Notes..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Mailing Address:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="address_line" 
                        type="text" 
                        placeholder="Mailing address..." 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <Row>
                        <Col xs="5" className="pl-0 pr-1">
                          <Field 
                            className="form-control" 
                            component={reduxFormInput}
                            validate={[required]}
                            name="city" 
                            type="text" 
                            placeholder="City..." 
                          />
                        </Col>
                        <Col xs="4" className="pl-0 pr-0">
                          <Field
                            component={reduxFormSelect}
                            validate={[required]}
                            options={states}
                            name="state"
                          />
                        </Col>
                        <Col xs="3" className="pl-1 pr-0">
                          <Field 
                            className="form-control" 
                            component={reduxFormInput}
                            validate={[required, number]}
                            name="zip" 
                            type="number" 
                            placeholder="Zip" 
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Created At:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      {moment(initialValues.created_at).format('MM/DD/YYYY HH:mm:ss')}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="3">
                        Updated At:
                    </Form.Label>
                    <Col xs="12" sm="9">
                      {moment(initialValues.updated_at).format('MM/DD/YYYY HH:mm:ss')}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs="12" sm="6">
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <Card>
                        <Card.Header>
                          <Card.Title>Certifications</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <DisabledCheckboxGroup name="certifications" options={form_certifications} />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <Card>
                        <Card.Header>
                          <Card.Title>Areas of Expertise</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <DisabledCheckboxGroup name="expertizes" options={form_expertises} />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <Card>
                        <Card.Header>
                          <Card.Title>Communication Method</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <CheckboxGroup name="communication" options={communication_method} />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <div className="table-responsive">
                        <Table 
                          columns={columns} 
                          data={upcomingMediations}
                          tableHeader={true} />
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <p><b>Confirmed {rate.confirmed_count} of {rate.total_mediations} mediations in the current calendar year.</b></p>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Col xs="12" sm="12">
                      <h3><b>Confirmation Rating : {rate.confirmation_rate}</b></h3>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={20} />
                  <Button type="submit" variant="primary-alt" size="lg">
                      Save
                  </Button>
                  <Spacer size={20} />
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </main>
    </>
  )
}

const CheckStatus = (props) => {
  return(
    <>
      <input type="checkbox" disabled checked={props.status === "Confirmed"}/>
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = isMediatorLoading(state) 
    || isCertificationLoading(state) 
    || isExpertiseLoading(state) 
    || state.mediationReducer.isLoading.includes('GET_UPCOMING_MEDIATIONS')
    || state.mediationReducer.isLoading.includes('GET_CONFIRMATION_RATE');
  const certifications = state.certificationReducer.certifications;
  const expertises = state.expertiseReducer.expertises;
  const initialValues = state.mediatorReducer.existingMediator;
  const existingImage = state.mediatorReducer.image;
  const imageUploaded = state.mediatorReducer.callSuccessful.includes('UPLOAD_IMAGE');
  const mediatorUpdated = state.mediatorReducer.callSuccessful.includes('UPDATE_MEDIATOR');
  const upcomingMediations = state.mediationReducer.upcomingMediations;
  const rate = state.mediationReducer.rate;
  return { isLoading, certifications, expertises, initialValues, existingImage, imageUploaded, mediatorUpdated, upcomingMediations, rate }
}

export default connect(
  mapStateToProps, 
  {
    fetchCertifications,
    fetchExpertises,
    fetchMediator,
    fetchUpcomingMediations,
    fetchConfirmationRate,
    addMediator,
    modifySelfMediator,
    uploadMediatorImage,
    downloadMediatorImage
  })(
  reduxForm({
    form: "editProfile",
    enableReinitialize: true,
  })(ViewProfile)
);
