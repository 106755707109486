import { actionTypes } from 'redux/actions/actionTypes';

const initialState = {
  isLoading: [],
  loadingFailed: [],
  callSuccessful: [],
  mmStatus: {}
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actionTypes.SAVE_MEDIATION_MEDIATOR: 
    return {
      ...state,
      isLoading: [...state.isLoading, "SAVE_MEDIATION_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'SAVE_MEDIATION_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'SAVE_MEDIATION_MEDIATOR'})
    }
  case actionTypes.SAVE_MEDIATION_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'SAVE_MEDIATION_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "SAVE_MEDIATION_MEDIATOR"],
      mmStatus: action.payload.data,
    }
  case actionTypes.SAVE_MEDIATION_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'SAVE_MEDIATION_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "SAVE_MEDIATION_MEDIATOR"],
      error: action.payload
    }
  case actionTypes.UPDATE_MEDIATION_MEDIATOR: 
    return {
      ...state,
      isLoading: [...state.isLoading, "UPDATE_MEDIATION_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION_MEDIATOR'})
    }
  case actionTypes.UPDATE_MEDIATION_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "UPDATE_MEDIATION_MEDIATOR"],
      mmStatus: action.payload.data,
    }
  case actionTypes.UPDATE_MEDIATION_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'UPDATE_MEDIATION_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "UPDATE_MEDIATION_MEDIATOR"],
      error: action.payload
    }
  case actionTypes.MEDIATOR_MEDIATION_UPDATE: 
    return {
      ...state,
      isLoading: [...state.isLoading, "MEDIATOR_MEDIATION_UPDATE"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_MEDIATION_UPDATE'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_MEDIATION_UPDATE'})
    }
  case actionTypes.MEDIATOR_MEDIATION_UPDATE_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_MEDIATION_UPDATE'}),
      callSuccessful: [...state.isLoading, "MEDIATOR_MEDIATION_UPDATE"],
      mmStatus: action.payload.data,
    }
  case actionTypes.MEDIATOR_MEDIATION_UPDATE_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'MEDIATOR_MEDIATION_UPDATE'}),
      loadingFailed: [...state.isLoading, "MEDIATOR_MEDIATION_UPDATE"],
      error: action.payload
    }
  case actionTypes.NOTIFY_MEDIATOR: 
    return {
      ...state,
      isLoading: [...state.isLoading, "NOTIFY_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'NOTIFY_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'NOTIFY_MEDIATOR'})
    }
  case actionTypes.NOTIFY_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'NOTIFY_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "NOTIFY_MEDIATOR"],
      mmStatus: action.payload.data,
    }
  case actionTypes.NOTIFY_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'NOTIFY_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "NOTIFY_MEDIATOR"],
      error: action.payload
    }
  case actionTypes.MEDIATOR_REMOVED:
    return {
      ...state,
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'CANCEL_MEDIATION_MEDIATOR'})
    }
  case actionTypes.CANCEL_MEDIATION_MEDIATOR: 
    return {
      ...state,
      isLoading: [...state.isLoading, "CANCEL_MEDIATION_MEDIATOR"],
      loadingFailed: state.isLoading.filter(function(value){ return value !== 'CANCEL_MEDIATION_MEDIATOR'}),
      callSuccessful: state.isLoading.filter(function(value){ return value !== 'CANCEL_MEDIATION_MEDIATOR'})
    }
  case actionTypes.CANCEL_MEDIATION_MEDIATOR_SUCCESS:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'CANCEL_MEDIATION_MEDIATOR'}),
      callSuccessful: [...state.isLoading, "CANCEL_MEDIATION_MEDIATOR"]
    }
  case actionTypes.CANCEL_MEDIATION_MEDIATOR_FAILURE:
    return {
      ...state,
      isLoading: state.isLoading.filter(function(value){ return value !== 'CANCEL_MEDIATION_MEDIATOR'}),
      loadingFailed: [...state.isLoading, "CANCEL_MEDIATION_MEDIATOR"],
      error: action.payload
    }
  default: 
    return state
  }
}