import React from 'react';
import { connect } from 'react-redux';
import { reset, reduxForm } from 'redux-form';
import { userPasswordReset } from 'redux/actions/auth';

import Header from 'components/layout/LoginHeader';
import Spacer from 'components/layout/Spacer';

import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/ui/Loader";
import { toast } from 'react-toastify';

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    let user;
    const path = this.props.history.location.pathname;
    if(path === '/admin/login/forgot') {
      user = 'administrator';
    } else if(path === '/mediator/login/forgot') {
      user = 'mediator';
    } else if(path === '/scheduler/login/forgot') {
      user = 'scheduler';
    }
    this.state = { user };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state && this.state.email) {
      const { email } = this.state;
      this.props.userPasswordReset(this.state.user, email);
    }
  }

  render() {
    const { isLoggingIn, message } = this.props;
    
    if(message) {
      this.props.dispatch(reset('forgotPassword'));
      this.props.dispatch({ type: 'USER_LOGOUT' });
      return <Redirect to='/' />
    }
    
    return(
      <>
        <Header type={`${this.state.user[0].toUpperCase()}${this.state.user.slice(1)} Portal`} />
        { isLoggingIn && <Loader /> }
        <main className="fc-content" id="login">
          <Spacer size={100} />
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs="12" md="8">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group as={Row} controlId="email">
                    <Form.Label className="pr-0 pt-0 pb-0" column sm="3">
                      Email
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control type="email" onChange={this.handleChange} required/>
                    </Col>
                  </Form.Group>

                  <Row>
                    <Col className="text-right">
                      Enter your registered email ID to reset the password
                      <Spacer size={15} />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-right">
                      <Button type="submit" className="btn-login" variant="secondary" size="lg">
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </main>
      </>
    )
  }
}

const mapStateToProps = state => {
  if (state.authReducer.loginFailed) {
    toast.error("Email is not registered!");
  }
  const isLoggingIn = state.authReducer.isLoggingIn;
  const message = state.authReducer.data;
  return { isLoggingIn, message };
}

const connectedReduxForm = reduxForm({
  form: 'forgotPassword',
  enableReinitialize: true,
})(ForgotPassword);

export default connect(
  mapStateToProps,
  { userPasswordReset }
)(connectedReduxForm)
