import { actionTypes } from './actionTypes';
import axios from 'axios';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';

export function createCertification() {
  return {
    type: actionTypes.CREATE_CERTIFICATION
  }
}
  
export function createCertificationSuccess(certification) {
  return {
    type: actionTypes.CREATE_CERTIFICATION_SUCCESS,
    payload: certification
  }
}
  
export function createCertificationFailure(error) {
  return {
    type: actionTypes.CREATE_CERTIFICATION_FAILURE,
    payload: error
  }
}

export function getCertifications() {
  return {
    type: actionTypes.GET_CERTIFICATIONS
  }
}
      
export function getCertificationsSuccess(certifications) {
  return {
    type: actionTypes.GET_CERTIFICATIONS_SUCCESS,
    payload: certifications
  }
}
      
export function getCertificationsFailure(error) {
  return {
    type: actionTypes.GET_CERTIFICATIONS_FAILURE,
    payload: error
  }
}

export function updateCertification() {
  return {
    type: actionTypes.UPDATE_CERTIFICATION
  }
}
  
export function updateCertificationSuccess(certification) {
  return {
    type: actionTypes.UPDATE_CERTIFICATION_SUCCESS,
    payload: certification
  }
}
  
export function updateCertificationFailure(error) {
  return {
    type: actionTypes.UPDATE_CERTIFICATION_FAILURE,
    payload: error
  }
}

export function deleteCertification() {
  return {
    type: actionTypes.DELETE_CERTIFICATION
  }
}
  
export function deleteCertificationSuccess() {
  return {
    type: actionTypes.DELETE_CERTIFICATION_SUCCESS
  }
}
  
export function deleteCertificationFailure(error) {
  return {
    type: actionTypes.DELETE_CERTIFICATION_FAILURE,
    payload: error
  }
}

export const addCertification = (certification) => {
  return dispatch => {
    dispatch(createCertification());
    axios
      .post(`${constants.url}/certifications`, certification,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(createCertificationSuccess(res)))
      .catch(err => dispatch(createCertificationFailure(err)))
  }
}

export const fetchCertifications = () => {
  return dispatch => {
    dispatch(getCertifications());
    axios
      .get(`${constants.url}/certifications`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getCertificationsSuccess(res.data)))
      .catch(err => dispatch(getCertificationsFailure(err)))
  }
}

export const modifyCertification = (id, certification) => {
  return dispatch => {
    dispatch(updateCertification());
    axios
      .put(`${constants.url}/certifications/${id}`, certification,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateCertificationSuccess(res.data)))
      .catch(err => dispatch(updateCertificationFailure(err)))
  }
}

export const removeCertification = (id) => {
  return dispatch => {
    dispatch(deleteCertification());
    axios
      .delete(`${constants.url}/certifications/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(deleteCertificationSuccess()))
      .catch(err => dispatch(deleteCertificationFailure(err)))
  }
}
